import Axios from "@/plugins/Axios";
// import store from "@/store";
import GestaoDeAula from "@/Models/GestaoDeAula";
import FichaDiarioAtendimento from "../Models/FichaDiarioAtendimento";

export class GestoesDeAulasAEEService {
  async syncAll(pageNumber, query) {
    const response = await Axios().get(`gestoes-de-aulas-aee?page=${pageNumber}&query=${query}`);

    // store.commit("GestoesDeAulas/setGestoesDeAulas", gestoesDeAulas);
    return response.data;
  }

  async listarAulasGestao(gestaoDeAulaId) {
    const response = await Axios().post("gestoes-de-aulas-aee/listar-aulas-gestao", {
      gestao_id: gestaoDeAulaId,
    });

    const fichas = response.data.gestaoDeAula.fichas.map(
      (fichas) => new FichaDiarioAtendimento(fichas)
    );
    const payload = response.data;
    return { fichas, payload };
  }

  async visualizar(gestaoDeAula) {
    const response = await Axios().get(`gestoes-de-aulas-aee/${gestaoDeAula}/visualizar`);
    return response.data;
    // store.commit("GestoesDeAulas/adicionarGestaoDeAula", novaGestaoDeAula);
    // return novaGestaoDeAula;
  }

  async visualizarTurma(gestaoDeAula) {
    const response = await Axios().get(`gestoes-de-aulas-aee/${gestaoDeAula}/visualizar/turma`);
    return response.data;
  }

  async gestaoGeralId(gestaoDeAulaId) {
    const filtro = true;
    const response = await Axios().get(
      `gestoes-de-aulas-aee/${gestaoDeAulaId}/visualizar/${filtro}`
    );
    return response.data;
    // store.commit("GestoesDeAulas/adicionarGestaoDeAula", novaGestaoDeAula);
    // return novaGestaoDeAula;
  }

  async geral() {
    const response = await Axios().get(`gestoes-de-aulas-aee/geral`);
    const gestoesDeAulasGeral = response.data.map(
      (gestaoDeAulas) => new GestaoDeAula(gestaoDeAulas)
    );
    // store.commit("GestoesDeAulas/setGestoesDeAulas", gestoesDeAulasGeral);

    return gestoesDeAulasGeral;
  }

  async geralPaginate(pageNumber, query) {
    const response = await Axios().get(
      `gestoes-de-aulas-aee/geral?page=${pageNumber}&query=${query}`
    );

    return response.data;
  }

  async listarGestoesProfessor(gestao) {
    const response = await Axios().post(`gestoes-de-aulas-aee/listar-gestoes-professor`, {
      gestao,
    });
    return response.data;
  }

  async listarGestaoDeAulasDaTurma(turma_id) {
    const response = await Axios().post(`gestoes-de-aulas-aee/listar-gestoes-aulas-turma`, {
      turma_id,
    });
    return response.data;
    // store.commit("GestoesDeAulas/adicionarGestaoDeAula", novaGestaoDeAula);
    // return novaGestaoDeAula;
  }

  async criar(relacoesList) {
    const response = await Axios().post("gestoes-de-aulas-aee", relacoesList);
    return response.data;
    // store.commit("GestoesDeAulas/adicionarGestaoDeAula", novaGestaoDeAula);
    // return novaGestaoDeAula;
  }

  async atualizar(gestaoDeAulas) {
    const response = await Axios().put(`gestoes-de-aulas-aee/${gestaoDeAulas.id}`, gestaoDeAulas);
    const gestaoDeAulasAtualizada = response.data;
    // store.commit("GestoesDeAulas/atualizarGestaoDeAula", gestaoDeAulasAtualizada);
    return gestaoDeAulasAtualizada;
  }

  async deletarGestaoDeAula(gestaoDeAulas) {
    const response = await Axios().delete(
      `gestoes-de-aulas-aee/deletar-gestao/${gestaoDeAulas.id}`
    );
    const gestaoDeAulasDeletada = response.data;
    // store.commit("GestoesDeAulas/deletarGestaoDeAula", gestaoDeAulas.id);
    return gestaoDeAulasDeletada;
  }

  async editarGestaoDeAula(gestaoDeAulas) {
    const response = await Axios().edit(`gestoes-de-aulas-aee/visualizar/${gestaoDeAulas.id}`);
    const gestaoDeAulasEditada = response.data;
    return gestaoDeAulasEditada;
  }

  async recuperarGestaoDeAula(gestaoDeAulas) {
    return this.atualizarGestaoDeAula({ ...gestaoDeAulas, deleted_at: null });
  }

  async historicoAulasLancadas(gestaoDeAulaId) {
    const response = await Axios().get(
      `gestoes-de-aulas-aee/historico-aulas-lancadas/${gestaoDeAulaId}`
    );
    return response.data;
    // store.commit("GestoesDeAulas/adicionarGestaoDeAula", novaGestaoDeAula);
    // return novaGestaoDeAula;
  }

  async historicoPlanosLancados(gestaoDeAulaId, bimestre) {
    const response = await Axios().get(
      `gestoes-de-aulas-aee/historico-planos-lancados/${gestaoDeAulaId}/${bimestre}`
    );
    return response.data;
    // store.commit("GestoesDeAulas/adicionarGestaoDeAula", novaGestaoDeAula);
    // return novaGestaoDeAula;
  }

  async listarDisciplinas(idGestao) {
    const response = await Axios().get(`listar-disciplinas/${parseInt(idGestao, 10)}`);
    return response;
  }

  async listarPerguntas(idDisciplina, gestao, mes_id) {
    const response = await Axios().get(
      `gestoes-de-aulas-aee/listar-perguntas/${parseInt(idDisciplina, 10)}/${gestao}/${mes_id}`
    );
    return response;
  }

  async salvar(form, disciplina, gestao, mes_id) {
    const response = await Axios().post(
      `gestoes-de-aulas-aee/salvar/${disciplina}/${gestao}/${mes_id}`,
      { form }
    );
    return response;
  }
}

export default new GestoesDeAulasAEEService();
