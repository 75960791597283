import Axios from "@/plugins/Axios";

class GrauParentesco {
  async listAll() {
    const response = await Axios().get("graus-parentesco");
    return response.data;
  }
}

export default new GrauParentesco();
