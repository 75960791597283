import Axios from "@/plugins/Axios";
import FichaDiarioAtendimento from "../Models/FichaDiarioAtendimento";

export class EducacaoInclusivaService {
  async syncAll(form) {
    const response = await Axios().post(`educacao-inclusiva`, form);
    const payload = response.data;
    return payload;
  }

  async lancar(form) {
    await Axios().post(`educacao-inclusiva/salvar-relatorio`, form);
  }

  async lancarFichaDeDesenvolvimentoAnual(form) {
    await Axios().post(`educacao-inclusiva/salvar-ficha-de-desenvolvimento-anual`, form);
  }

  async carregarrFichaDeDesenvolvimentoAnual(matricula_id, gestaoDeAulasId, semestre) {
    const response = await Axios().get(
      `educacao-inclusiva/carregar-ficha-de-desenvolvimento-anual/${matricula_id}/${gestaoDeAulasId}/${semestre}`
    );
    const payload = response.data;
    return payload;
  }

  // Mensal
  async lancarFichaDeDesenvolvimentoMensal(form) {
    await Axios().post(`educacao-inclusiva/salvar-ficha-de-desenvolvimento-mensal`, form);
  }

  // Bimestral
  async lancarFichaDeDesenvolvimentoBimestral(form) {
    await Axios().post(`educacao-inclusiva/salvar-ficha-de-desenvolvimento-bimestral`, form);
  }

  async carregarrFichaDeDesenvolvimentoBimestral(matricula_id, gestaoDeAulasId, bimestre) {
    const response = await Axios().get(
      `educacao-inclusiva/carregar-ficha-de-desenvolvimento-bimestral/${matricula_id}/${gestaoDeAulasId}/${bimestre}`
    );
    const payload = response.data;
    return payload;
  }
  //

  // Diario
  async lancarFichaDeDiarioAtendimento(form) {
    await Axios().post(`educacao-inclusiva/salvar-ficha-de-diario-atendimento`, form);
  }

  async carregarFichaDeDiarioAtendimento(matricula_id, gestaoDeAulasId) {
    const response = await Axios().get(
      `educacao-inclusiva/carregar-ficha-de-diario-atendimento/${matricula_id}/${gestaoDeAulasId}`
    );
    const payload = new FichaDiarioAtendimento(response.data);
    return payload;
  }

  async lancarFichaDeDiarioAtendimentoEdit(form) {
    await Axios().post(`educacao-inclusiva/salvar-ficha-de-diario-atendimento-edit`, form);
  }

  async mudarPresenca(id) {
    await Axios().get(`educacao-inclusiva/presenca/${id}`);
  }

  async mudarConfirmacao(id) {
    await Axios().get(`educacao-inclusiva/confirmacao/${id}`);
  }

  async carregarFichaDeDiarioAtendimentoEdit(id) {
    const response = await Axios().get(
      `educacao-inclusiva/carregar-ficha-de-diario-atendimento-edit/${id}`
    );

    return new FichaDiarioAtendimento(response.data);
  }

  async deletar(ficha) {
    const response = await Axios().delete(`educacao-inclusiva/${ficha.id}`);
    const diarioDeletada = response.data;
    return diarioDeletada;
  }

  //

  async carregarrFichaDeDesenvolvimentoMensal(matricula_id, mes) {
    const response = await Axios().get(
      `educacao-inclusiva/carregar-ficha-de-desenvolvimento-mensal/${matricula_id}/${mes}`
    );
    const payload = response.data;
    return payload;
  }

  async lancarFichaDeDesenvolvimentoInfantil(form) {
    await Axios().post(`educacao-inclusiva/salvar-ficha-de-desenvolvimento-infantil`, form);
  }

  async carregarrFichaDeDesenvolvimentoInfantil(matricula_id) {
    const response = await Axios().get(
      `educacao-inclusiva/carregar-ficha-de-desenvolvimento-infantil/${matricula_id}`
    );
    const payload = response.data;
    return payload;
  }

  async lancarFichaDeDesenvolvimentoFinal(form) {
    await Axios().post(`educacao-inclusiva/salvar-ficha-de-desenvolvimento-final`, form);
  }

  async carregarrFichaDeDesenvolvimentoFinal(matricula_id) {
    const response = await Axios().get(
      `educacao-inclusiva/carregar-ficha-de-desenvolvimento-final/${matricula_id}`
    );
    const payload = response.data;
    return payload;
  }

  async lancarFichaDeDesenvolvimentoInicial(form) {
    await Axios().post(`educacao-inclusiva/salvar-ficha-de-desenvolvimento-inicial`, form);
  }

  async carregarrFichaDeDesenvolvimentoInicial(matricula_id) {
    const response = await Axios().get(
      `educacao-inclusiva/carregar-ficha-de-desenvolvimento-inicial/${matricula_id}`
    );
    const payload = response.data;
    return payload;
  }
}
export default new EducacaoInclusivaService();
