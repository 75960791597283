<template>
  <main-template>
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col>
            <h1>
              <v-btn
                color="primary"
                x-small
                text
                @click="() => $router.push({ name: 'gestoesDeAulasAEE' })"
                :disabled="submittingForm"
              >
                <v-icon left> fa fa-angle-left </v-icon>
              </v-btn>
              DIÁRIO ATENDIMENTO
            </h1>
          </v-col>
        </v-row>
      </v-col>
      <v-col>
        <v-card>
          <v-card-title>DIÁRIO DE DESENVOLVIMENTO</v-card-title>
          <v-card-text>
            <h4>Turma: {{ gestao?.turma ? gestao?.turma.descricao : "- - -" }}</h4>
            <h4>Aluno: {{ matricula?.aluno ? matricula?.aluno.nomecompleto : "- - -" }}</h4>
          </v-card-text>
          <v-divider class="mx-4"></v-divider>
          <v-card-title>Professores (diários)</v-card-title>
          <v-card-text>
            <div>Professor(a): {{ gestao?.professor ? gestao?.professor.nome : "- - -" }}</div>
          </v-card-text>
        </v-card>
        <v-col cols="12" class="pt-3 px-0">
          <div class="d-flex justify-space-between">
            <v-btn
              color="success"
              :to="{
                name: 'educacaoInclusivaDiario.create',
                params: { matricula_id: $route.params.matricula_id },
                params: { gestao_id: $route.params.gestaoDeAulasId },
              }"
              class="mr-2"
              >Criar atendimento</v-btn
            >
            <v-text-field
              v-model="search"
              class="ml-2 search-input"
              append-icon="mdi-magnify"
              label="Pesquisar..."
              solo
              autofocus
              dense
              single-line
              hide-details
            />
          </div>
        </v-col>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <v-data-table
          :headers="table.headers"
          :items="aulasList"
          :items-per-page="10"
          :custom-filter="dataTableFilter"
          :search="search"
          class="elevation-1"
        >
          <template v-slot:body="{ items }">
            <tbody name="scroll-x-transition" is="transition-group" duration="150">
              <tr
                color="primary"
                :class="ficha.estaDeletado ? ['deleted'] : []"
                v-for="ficha of items"
                :key="ficha.id"
              >
                <td>{{ ficha.id }}</td>
                <td>{{ ficha.data_formatada }}</td>
                <td class="text-left">{{ ficha.horario ? ficha.horario.descricao : "- - -" }}</td>
                <td class="text-center">
                  <v-tooltip bottom v-if="ficha.aluno_presente === 1">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="green"
                        v-bind="attrs"
                        v-on="on"
                        @click="changePresenca(ficha.id)"
                      >
                        <v-icon small>fa fa-thumbs-up </v-icon>
                      </v-btn>
                    </template>
                    <span>Aluno Presente</span>
                  </v-tooltip>
                  <v-tooltip bottom v-else>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="red"
                        v-bind="attrs"
                        v-on="on"
                        @click="changePresenca(ficha.id)"
                      >
                        <v-icon small>fa fa-thumbs-up </v-icon>
                      </v-btn>
                    </template>
                    <span>Aluno Ausente</span>
                  </v-tooltip>
                </td>
                <td class="text-left">
                  <!-- Confirmar aulas inicio -->
                  <v-tooltip bottom v-if="ficha.confirmada === 1">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="green"
                        v-bind="attrs"
                        v-on="on"
                        @click="changeConfirmacao(ficha.id)"
                      >
                        <v-icon small>fa fa-thumbs-up </v-icon>
                      </v-btn>
                    </template>
                    <span>Aula confirmada</span>
                  </v-tooltip>
                  <v-tooltip bottom v-else>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="red"
                        v-bind="attrs"
                        v-on="on"
                        @click="changeConfirmacao(ficha.id)"
                      >
                        <v-icon small>fa fa-thumbs-up </v-icon>
                      </v-btn>
                    </template>
                    <span>Confirmar aula</span>
                  </v-tooltip>
                  <!-- Confirmar aulas inicio fim -->
                </td>
                <td width="25%" class="text-center">
                  <!-- <v-tooltip color="primary" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => verMotivoAula(ficha)"
                      >
                        <v-icon small>fa fa-eye </v-icon>
                      </v-btn>
                    </template>
                    <span>Visualizar aula </span>
                  </v-tooltip> -->

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="blue-grey lighten-3"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => editarDiario(ficha)"
                      >
                        <v-icon small>fa fa-edit </v-icon>
                      </v-btn>
                    </template>
                    <span>Editar Diário </span>
                  </v-tooltip>
                  <v-tooltip color="error" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="error"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => deletarDiario(ficha)"
                      >
                        <v-icon small>fa fa-trash </v-icon>
                      </v-btn>
                    </template>
                    <span>Apagar Diário </span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <e-drawer-motivo-aula v-model="verMotivo" :aula="aula" :aee="true"> </e-drawer-motivo-aula>
  </main-template>
</template>

<script>
import { dataTableFilter } from "@/plugins/searchQuery";

export default {
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
    gestaoDeAulaId: {
      type: [String, Number],
      require: true,
    },
  },
  mounted() {
    this.loadBasicData();
  },
  data() {
    return {
      form: {
        data: null,
        horario_aee_id: {
          descricao: "Escolha um horario",
        },
      },
      aula: {},
      verMotivo: false,
      dataTableFilter,
      gestoesDeAulasList: [],
      aulasList: [],
      carregandoAulas: false,
      modal: false,
      menu: false,
      horarios: [],
      gestao: null,
      matricula: null,
      gestao_de_aulas: [],
      submittingForm: false,
      search: "",
      aulaSelecionada: {
        data: null,
      },
      data: null,
      table: {
        headers: [
          { text: "#", value: "id" },
          { text: "Data", value: "data_formatada" },
          { text: "Horário", value: "horario_descricao" },
          { text: "Presença do Aluno", value: "presente" },
          { text: "Confirmada", value: "confirmada" },

          {
            text: "Ações",
            align: "center",
            value: "actions",
            sortable: false,
            width: "200px",
          },
        ],
      },
    };
  },

  methods: {
    async changePresenca(id) {
      await this.$services.educacaoInclusivaService.mudarPresenca(id);
      this.loadBasicData();
    },
    async changeConfirmacao(id) {
      try {
        await this.$services.educacaoInclusivaService.mudarConfirmacao(id);
        this.loadBasicData();
      } catch (error) {
        this.$handleError(error);
      }
    },
    verMotivoAula(aula) {
      this.verMotivo = !this.verMotivo;
      this.aula = aula;
    },

    editarDiario(ficha) {
      this.$router.push({
        name: "educacaoInclusivaDiario.edit",
        params: { id: ficha.id, matricula_id: ficha.matricula_id },
      });
    },

    async deletarDiario(ficha) {
      this.$modals.danger({
        title: "Cuidado!",
        message: "Tem certeza que deseja apagar este diário?",
        confirmationCode: ficha.id,
        confirmationMessage: `Por favor, digite <strong>${ficha.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Deletando o Diário");
              try {
                await this.$services.educacaoInclusivaService.deletar(ficha);
                this.aulasList = this.aulasList.filter((item) => item.id !== ficha.id);
                this.$toast.success("Diário deletado com sucesso");
                this.$forceUpdate();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              small: true,
            },
          },
        ],
      });
    },

    async createOrUpdate() {
      this.submittingForm = true;
      this.$loaderService.open("Carregando dados...");
      try {
        this.form.data = this.data;
        const { gestao_id } = this.$route.params;
        this.form.gestao_id = gestao_id;
        this.$toast.info("Relatório salvo com sucesso!");
        await this.$services.educacaoInclusivaService.lancarFichaDeDiarioAtendimento(this.form);
      } catch (error) {
        this.$handleError(error);
      }
      this.$router.push({ name: "gestoesDeAulasAuxiliares" });

      this.submittingForm = false;
    },

    async loadBasicData() {
      this.carregandoAulas = true;
      this.$loaderService.open("Carregando dados...");
      try {
        const { matricula_id } = this.$route.params;
        const { gestaoDeAulasId } = this.$route.params;
        const response =
          await this.$services.educacaoInclusivaService.carregarFichaDeDiarioAtendimento(
            matricula_id,
            gestaoDeAulasId
          );

        this.aulasList = response.ficha;
        this.form = response.ficha ? response.ficha : {};
        this.lancarRelatorio = this.$constants.fichaEducacaoEspecialDiario;
        this.gestao = response.gestao;
        this.matricula = response.matricula;
        this.horarios = response.horarios;
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
  },
};
</script>

<style></style>
