<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="
              () =>
                $router.push({
                  name: 'gestoesDeAulas',
                })
            "
            :disabled="submittingForm"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          {{ editing ? "Editando" : "Criando" }} Plano Mensal
        </h1>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="dialog" max-width="600">
        <v-card>
          <v-card-title class="text-h5"> Deseja continuar preenchendo os planos? </v-card-title>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="success" @click="back()"> Salvar e Sair </v-btn>

            <v-btn color="green darken-1" class="white--text" @click="continuarFluxo()">
              Salvar e Continuar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row>
      <v-col cols="12" v-if="gestaoDeAula">
        <v-card color="green lighten-1" outlined>
          <v-col cols="10">
            <v-card-text>
              <h4>Turma: {{ gestaoDeAula.turma?.descricao }}</h4>
              <h4>
                Disciplina:
                {{
                  gestaoDeAula.is_polivalencia === 1
                    ? gestaoDeAula.disciplina
                    : gestaoDeAula.disciplina.descricao
                }}
              </h4>
              <h4>
                Professor:
                {{ gestaoDeAula.professor ? gestaoDeAula.professor.nome : "[carregando...]" }}
              </h4>
            </v-card-text>
          </v-col>
          <v-col cols="2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  x-small
                  class="ml-1 white--text"
                  color="blue"
                  v-bind="attrs"
                  v-on="on"
                  @click="() => verHistoricoPlanos()"
                >
                  <span>Histórico do lancamento de planos</span>
                </v-btn>
              </template>
            </v-tooltip>
          </v-col>
        </v-card>
      </v-col>
      <v-col cols="12 text-center" class="pt-2">
        <template>
          <v-toolbar>
            <v-tabs dark v-model="form.mes_id" background-color="primary" grow>
              <v-tab
                v-for="item of $constants.mesesPlanoMensal"
                :key="item.id"
                @click="() => editarPlano(item.id)"
              >
                <v-badge v-if="form.mes_id === item.id" color="deep-orange accent-3">
                  {{ item.descricao }}
                </v-badge>
                <div v-else>{{ item.descricao }}</div>
              </v-tab>
            </v-tabs>
          </v-toolbar>
        </template>
      </v-col>
    </v-row>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form
        @submit.prevent="handleSubmit(createOrUpdate)"
        v-if="loadPlano"
        :disabled="submittingForm"
      >
        <v-row>
          <v-col cols="12" md="6">
            <ValidationProvider name="Tematica" rules="required" v-slot="{ errors }">
              <e-label>UNIDADES TEMÁTICAS</e-label>
              <v-textarea solo dense v-model="form.tematica" :error-messages="errors" />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6">
            <ValidationProvider name="Metodologia" rules="required" v-slot="{ errors }">
              <e-label>OBJETOS DO CONHECIMENTO</e-label>
              <v-textarea solo dense v-model="form.objeto_conhecimento" :error-messages="errors" />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6">
            <ValidationProvider name="Habilidades" rules="required" v-slot="{ errors }">
              <e-label>HABILIDADES</e-label>
              <v-textarea solo dense v-model="form.habilidades" :error-messages="errors" />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6">
            <ValidationProvider name="Obervacoes" rules="required" v-slot="{ errors }">
              <e-label>OBSERVAÇÕES</e-label>
              <v-textarea solo dense v-model="form.observacoes" :error-messages="errors" />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6">
            <ValidationProvider name="Metodologia" rules="required" v-slot="{ errors }">
              <e-label>METODOLOGIA</e-label>
              <v-textarea solo dense v-model="form.metodologia" :error-messages="errors" />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6">
            <ValidationProvider name="Recursos didaticos" rules="required" v-slot="{ errors }">
              <e-label>RECURSOS DIDÁTICOS</e-label>
              <v-textarea solo dense v-model="form.recursos_didaticos" :error-messages="errors" />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6">
            <ValidationProvider
              name="Avaliação da aprendizagem"
              rules="required"
              v-slot="{ errors }"
            >
              <e-label>AVALIAÇÃO DA APRENDIZAGEM</e-label>
              <v-textarea
                solo
                dense
                v-model="form.avaliacao_aprendizagem"
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6">
            <ValidationProvider name="Referencia" rules="required" v-slot="{ errors }">
              <e-label>REFERÊNCIAS</e-label>
              <v-textarea solo dense v-model="form.referencias" :error-messages="errors" />
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-col cols="12" md="6">
          <v-row>
            <v-btn
              color="primary"
              :disabled="submittingForm"
              :loading="submittingForm"
              type="submit"
              >Enviar Dados</v-btn
            >
            <v-btn color="success" class="ml-3" @click="modal = true">Exportar plano</v-btn>
          </v-row>
        </v-col>
      </form>
      <v-dialog v-model="modal" persistent max-width="800px">
        <v-card>
          <v-card-title class="primary">
            <span style="color: #fff" class="text-h5">Exportar Plano</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container>
              <ValidationObserver v-slot="{ handleSubmit }">
                <form
                  v-if="show"
                  @submit.prevent="handleSubmit(exportarPlano)"
                  :disabled="submittingForm"
                >
                  <v-row>
                    <v-col class="pt-4 pb-0" cols="12">
                      <e-label class="font-weight-medium">Turma</e-label>
                      <p class="font-weight-regular">
                        <v-select
                          :items="turmas"
                          item-text="turma.descricao"
                          item-value="turma.id"
                          return-object
                          label="Selecione o campo abaixo"
                          v-model="turmaSelecionada"
                          @change="(item) => setGestao(item)"
                        ></v-select>
                      </p>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12">
                      <e-label class="font-weight-medium">UNIDADES TEMÁTICAS</e-label>
                      <p class="font-weight-regular">
                        {{ form.tematica }}
                      </p>
                    </v-col>
                    <v-col cols="12">
                      <e-label class="font-weight-medium">OBJETOS DO CONHECIMENTO</e-label>
                      <p class="font-weight-regular">
                        {{ form.objeto_conhecimento }}
                      </p>
                    </v-col>
                  </v-row>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn style="color: #fff" class="red darken-1" @click="modal = false">
                      Fechar
                    </v-btn>
                    <v-btn
                      :disabled="submittingForm"
                      :loading="submittingForm"
                      type="submit"
                      style="color: #fff"
                      class="green darken-1"
                      >Salvar</v-btn
                    >
                  </v-card-actions>
                </form>
              </ValidationObserver>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </ValidationObserver>
    <e-drawer-planos-lancados
      v-model="openDrawer"
      :gestao_id="parseInt($route.params.gestaoDeAulaId, 10)"
      :mes_id="parseInt(form.mes_id, 10)"
      :disciplina_id="parseInt(form.disciplina_id, 10)"
    />
  </main-template>
</template>

<script>
import ELabel from "../../components/ELabel.vue";

export default {
  components: { ELabel },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
    gestaoDeAulaId: {
      type: [String, Number],
      require: true,
    },
  },
  mounted() {
    this.loadData();
  },
  data() {
    return {
      submittingForm: false,
      formExportarPlano: {
        mes_id: 0,
      },
      form: {
        mes_id: 0,
        disciplina_id: 0,
      },
      dialog: false,
      openDrawer: false,
      gestaoDeAula: {
        turma: {
          descricao: "",
        },
        disciplina: {
          descricao: "",
        },
        disciplinas: [],
      },
      loadPlano: true,
      modal: false,
      show: true,
      turmaSelecionada: null,
      export_gestao: null,
      turmas: null,
    };
  },
  methods: {
    async verHistoricoPlanos() {
      this.openDrawer = !this.openDrawer;
    },
    async editarPlano(mes_id) {
      this.loadPlano = false;
      this.$loaderService.open("Carregando dados...");
      try {
        const { gestaoDeAulaId } = this.$route.params;
        this.form.instrutorDisciplinaTurma_id = gestaoDeAulaId;
        this.form.disciplina_id = this.disciplinaId;
        await this.$services.planosMensaisService.createOrUpdate(this.form);
        const response = await this.$services.planosMensaisService.planoMensal(
          gestaoDeAulaId,
          mes_id,
          this.disciplinaId
        );
        if (response !== "") {
          this.form = response;
          this.loadPlano = true;
          this.$loaderService.close();
          return;
        }
        this.form = {
          mes_id,
        };
      } catch (error) {
        this.$handleError(error);
      }
      this.loadPlano = true;
      this.$loaderService.close();
    },
    back() {
      this.$toast.success("Plano mensal salvo com sucesso!");
      this.$router.go(-1);
    },
    continuarFluxo() {
      this.$toast.success("Plano mensal salvo com sucesso!");
      this.dialog = false;
    },
    async createOrUpdate() {
      this.$loaderService.open("Carregando dados...");
      try {
        const { gestaoDeAulaId } = this.$route.params;
        const { mes_id } = this.form;
        this.form.mes_id = mes_id;
        this.form.instrutorDisciplinaTurma_id = gestaoDeAulaId;
        this.form.disciplina_id = this.disciplinaId;
        await this.$services.planosMensaisService.createOrUpdate(this.form);
        this.dialog = true;
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async loadData() {
      const { gestaoDeAulaId } = this.$route.params;
      this.disciplinaId = parseInt(this.$route.params.disciplinaId, 10);
      const { meses } = this.form;

      try {
        this.$loaderService.open("Carregando dados...");
        this.gestaoDeAula = await this.$services.gestoesDeAulasService.visualizar(gestaoDeAulaId);

        const response = await this.$services.planosMensaisService.planoMensal(
          gestaoDeAulaId,
          meses,
          this.disciplinaId
        );

        const gestoes = await this.$services.gestoesDeAulasService.listarGestoesProfessor(
          this.gestaoDeAula
        );
        this.turmas = gestoes;

        if (this.gestaoDeAula.disciplinas.length > 0) {
          const disciplina = this.gestaoDeAula.disciplinas.filter((i) => {
            return i.id === this.disciplinaId;
          });

          this.gestaoDeAula.disciplina = disciplina[0].descricao;
        }

        if (response !== "") {
          this.form = response;
        }
        this.$emit("dialogChange", !this.dialog);
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async exportarPlano() {
      this.$loaderService.open("Exportando plano...");
      try {
        const { mes_id } = this.form;

        this.formExportarPlano.instrutorDisciplinaTurma_id = this.export_gestao;
        this.formExportarPlano.disciplina_id = this.disciplinaId;
        this.formExportarPlano.mes_id = mes_id;
        this.formExportarPlano.tematica = this.form.tematica;
        this.formExportarPlano.objeto_conhecimento = this.form.objeto_conhecimento;
        this.formExportarPlano.habilidades = this.form.habilidades;
        this.formExportarPlano.observacoes = this.form.observacoes;
        this.formExportarPlano.metodologia = this.form.metodologia;
        this.formExportarPlano.recursos_didaticos = this.form.recursos_didaticos;
        this.formExportarPlano.avaliacao_aprendizagem = this.form.avaliacao_aprendizagem;
        this.formExportarPlano.referencias = this.form.referencias;

        await this.$services.planosMensaisService.createOrUpdate(this.formExportarPlano);

        this.$toast.success("Plano Mensal exportado com sucesso!");
        this.dialog = false;
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    setGestao(item) {
      this.export_gestao = item.id;
    },
  },
};
</script>

<style></style>
