<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn color="primary" x-small @click="goBack" text>
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          {{ editing ? "Editando" : "Criando" }} Educação Inclusiva
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>{{ descricaoFicha }}</v-card-title>
          <v-card-text>
            <h4>Turma: {{ matricula ? matricula.turma.descricao : "- - -" }}</h4>
            <h4>Aluno: {{ matricula ? matricula.aluno.nomecompleto : "- - -" }}</h4>
          </v-card-text>
          <v-divider class="mx-4"></v-divider>
          <v-card-title>Professores (diários)</v-card-title>
          <v-card-text>
            <div v-for="diario in gestao_de_aulas" :key="diario.id">
              Professor(a): {{ diario.professor.nome }} | Disciplina:
              {{ diario.disciplina ? diario.disciplina.descricao : "" }}
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn class="caption ml-2" @click="show = !show" text
              >Legendas para auxiliar no lançamento da ficha de desenvolvimento</v-btn
            >

            <v-spacer></v-spacer>

            <v-btn icon @click="show = !show">
              <v-icon>{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
            </v-btn>
          </v-card-actions>
          <v-expand-transition>
            <div v-show="show">
              <v-divider></v-divider>

              <v-card-text>
                <v-chip v-for="(legenda, i) in legendas" :key="i" class="ma-2" small>
                  <strong>{{ i + 1 }}</strong
                  >&nbsp;-&nbsp;{{ legenda }}
                </v-chip>
              </v-card-text>
            </div>
          </v-expand-transition>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="space-around">
      <v-col cols="12" md="12">
        <v-sheet cols="12" md="12" elevation="2" class="v-sheet--outlined" rounded>
          <v-lazy>
            <div class="py-2 px-4 text-subtitle-2">Painel de gestão</div>
          </v-lazy>
          <v-divider />
          <v-sheet class="py-2 px-4" md="12">
            <v-row v-if="loadBimestre">
              <v-col class="text-center pt-2" cols="12" md="12">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
              </v-col>
            </v-row>
            <ValidationObserver v-else v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(submitForm)" :disabled="submittingForm">
                <v-col cols="12 mb-10">
                  <v-simple-table class="elevation-1">
                    <template v-slot:default>
                      <tbody v-for="(el, index) of lancarRelatorio" :key="index">
                        <tr v-if="descricoes[index]">
                          <td colspan="5" class="text-h6">
                            {{ descricoes[index] ? descricoes[index] : "- - -" }}
                          </td>
                        </tr>
                        <tr v-for="(item, i) in el" :key="i" :class="changeColor(index, item)">
                          <td>
                            <span class="font-weight-medium">
                              {{ i + 1 }}
                            </span>
                            - {{ item.descricao ? item.descricao : "- - -" }}
                          </td>
                          <td>
                            <v-select
                              class="mt-4"
                              :items="opcoes"
                              label="pontuar"
                              outlined
                              :value="form[index] ? form[index][item.form] : ''"
                              dense
                              @change="(value) => totalPontuacao(value, item, index)"
                            ></v-select>
                          </td>
                        </tr>
                        <tr class="grey lighten-3">
                          <td class="text-h6">
                            <span class="font-weight-medium"> Total </span>
                          </td>
                          <td class="text-h6 text-right">
                            <span class="font-weight-medium">
                              {{ form[index] ? form[index].total : "- - -" }}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <v-row>
                    <v-col cols="12" class="mt-5">
                      <e-label>OBSERVAÇÕES ADICIONAIS DA ÁREA ESPECÍFICA</e-label>
                      <v-textarea rows="3" solo dense v-model="form.observacoes" />
                    </v-col>
                    <v-col cols="12" class="py-2">
                      <p>PRESENÇA DE ASSISTENTE NA SALA</p>

                      <v-btn-toggle
                        v-model="form.presenca_assistente"
                        tile
                        color="deep-purple accent-3"
                        group
                      >
                        <v-btn value="sim"> Sim </v-btn>

                        <v-btn value="nao"> Não </v-btn>
                      </v-btn-toggle>
                    </v-col>
                    <v-col cols="12" class="py-2">
                      <p>FREQUÊNCIA DO EDUCANDO (A)</p>

                      <v-btn-toggle
                        v-model="form.frenquencia_educando"
                        tile
                        color="deep-purple accent-3"
                        group
                      >
                        <v-btn value="ruim"> RUIM </v-btn>
                        <v-btn value="regular"> REGULAR </v-btn>
                        <v-btn value="otima"> ÓTIMA </v-btn>
                      </v-btn-toggle>
                    </v-col>
                    <v-col cols="12">
                      <v-btn
                        color="primary"
                        :disabled="submittingForm"
                        :loading="submittingForm"
                        type="submit"
                        >Enviar Relatório</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-col>
              </form>
            </ValidationObserver>
          </v-sheet>
          <div></div>
        </v-sheet>
      </v-col>
    </v-row>
  </main-template>
</template>
<script>
export default {
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      submittingForm: false,
      loadBimestre: false,
      alert: false,
      show: true,
      lancarRelatorio: [],
      descricaoFicha:
        "FICHA DE DESENVOLVIMENTO DO ALUNO ENSINO FUNDAMENTAL- ANOS FINAIS EDUCAÇÃO ESPECIAL",
      form: {
        habilidades_congnitivas: {
          total: 0,
        },
        habilidades_interpessoais: {
          total: 0,
        },
        area_congnitiva: {
          total: 0,
        },
        area_motora: {
          total: 0,
        },
        area_socioafetivas: {
          total: 0,
        },
      },
      descricoes: {
        habilidades_congnitivas: "Habilidades Congnitivas",
        habilidades_interpessoais: "Habilidades Interpessoais",
        area_motora: "Área Motora",
        area_congnitiva: "Área Congnitiva",
        area_socioafetivas: "Área Socioafetivas",
      },
      matricula: null,
      opcoes: [1, 2, 3, 4],
      legendas: [
        "NÃO CONSEGUE",
        "CONSEGUE COM MUITA DIFICULDADE",
        "CONSEGUE COM DIFICULDADE MODERADA",
        "CONSEGUE FREQUENTEMENTE",
      ],
      professor: {},
      styleClass: ["red lighten-4", "light-green lighten-1"],
      gestao_de_aulas: [],
    };
  },
  mounted() {
    this.loadBasicData();
  },
  methods: {
    changeColor(index, item) {
      let countClass = 0;
      const itemForm = this.form[index] ? this.form[index][item.form] : null;
      if (itemForm) {
        countClass += 1;
      }
      return this.styleClass[countClass];
    },

    totalPontuacao(value, item, index) {
      // essa funcao soma de forma dinamica o total de cada tabela
      if (this.form[index][item.form] && this.form[index][item.form] !== value) {
        // esse if é usado para remover o valor se caso já existe se remover ele vai funcionar
        // mas na soma total irá ficando somando sempre mesmo se ja estiver o valor
        this.form[index].total -= this.form[index][item.form];
      }
      this.form[index].total += value;
      // depois ele é alterado
      this.form[index][item.form] = value;
    },
    async carregarBimestre(bimestre) {
      this.loadBimestre = true;
      await this.loadBasicData(bimestre);
      this.loadBimestre = false;
    },

    async loadBasicData() {
      this.$loaderService.open("Carregando dados...");
      try {
        const { matricula_id } = this.$route.params;

        const response =
          await this.$services.educacaoInclusivaService.carregarrFichaDeDesenvolvimentoInfantil(
            matricula_id
          );
        if (response.ficha) {
          this.form = response.ficha;
        }
        this.lancarRelatorio = this.$constants.FICHA_EDUCACAO_DESENVOLVIMENTO_ESPECIAL_INFANTIL;
        this.matricula = response.matricula;
        this.gestao_de_aulas = response.gestao_de_aulas;
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },

    async submitForm() {
      let response;
      try {
        const { matricula_id } = this.$route.params;
        this.form.matricula_id = matricula_id;
        this.$toast.info("Salvando relatório...");
        response =
          await this.$services.educacaoInclusivaService.lancarFichaDeDesenvolvimentoInfantil(
            this.form
          );
      } catch (error) {
        this.$handleError(error);
      }
      return response;
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style></style>
