<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="
              () =>
                $router.push({
                  name: 'gestoesDeAulas',
                })
            "
            :disabled="submittingForm"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          {{ editing ? "Editando" : "Criando" }} Plano Mensal Infantil
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" v-if="gestaoDeAula">
        <v-card color="green lighten-1" outlined>
          <v-col cols="10">
            <v-card-text>
              <h4>Turma: {{ gestaoDeAula.turma.descricao }}</h4>
              <h4>Disciplina: {{ gestaoDeAula.disciplina.descricao }}</h4>
              <h4>
                Professor:
                {{ gestaoDeAula.professor ? gestaoDeAula.professor.nome : "[carregando...]" }}
              </h4>
            </v-card-text>
          </v-col>

          <v-col cols="2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  x-small
                  class="ml-1 white--text"
                  color="blue"
                  v-bind="attrs"
                  v-on="on"
                  @click="() => verHistoricoPlanos()"
                >
                  <span>Histórico do lancamento de planos</span>
                </v-btn>
              </template>
            </v-tooltip>
          </v-col>
        </v-card>
      </v-col>

      <v-col cols="12 text-center" class="pt-2">
        <template>
          <v-toolbar>
            <v-tabs dark v-model="form.bimestre" background-color="primary" grow>
              <v-tab
                v-for="item of $constants.bimestres"
                :key="item.value"
                @click="() => loadData(item.value)"
              >
                <v-badge v-if="form.bimestre === item.value" color="success">
                  {{ item.desc }}
                </v-badge>
                <div v-else>{{ item.desc }}</div>
              </v-tab>
            </v-tabs>
          </v-toolbar>
        </template>
      </v-col>
    </v-row>

    <form @submit.prevent="createOrUpdate" v-if="loadPlano" :disabled="submittingForm">
      <v-row>
        <v-col cols="12">
          <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="mb-3 mt-5" color="blue" dark v-bind="attrs" v-on="on">
                INSIRA O CÓDIGO
              </v-btn>
            </template>

            <v-card>
              <v-toolbar dark color="blue">
                <v-btn icon dark @click="dialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>PESQUISA</v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>

              <v-col>
                <v-row>
                  <v-col cols="12">
                    <v-sheet color="white" elevation="1">
                      <div class="py-2 px-4" color="white">
                        <v-text-field
                          @blur="search.length === 0 ? (objetivosAprendizagem = []) : false"
                          v-model="search"
                          autofocus
                          v-on:keyup="() => procurarHabilidade()"
                          label="DIGITE O CODIGO!"
                        ></v-text-field>
                      </div>
                    </v-sheet>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="6">
                    <v-sheet color="white" elevation="1" rounded>
                      <v-lazy>
                        <div class="text-start py-2 px-4 text-button blue white--text">
                          HABILIDADES ENCONTRADAS
                          <v-icon dark right> mdi-magnify </v-icon>
                        </div>
                      </v-lazy>
                      <v-divider class="theme--light"></v-divider>

                      <div class="py-2 px-4">
                        <v-skeleton-loader
                          v-if="carregandoHabilidades"
                          class="mx-auto"
                          max-width="auto"
                          type="list-item"
                        ></v-skeleton-loader>

                        <v-list-item
                          class="grey lighten-3 mb-3 pa-md-4"
                          v-for="m in objetivosAprendizagem"
                          :key="m.id"
                        >
                          <v-col cols="12" sm="10" class="d-flex flex-column justify-start">
                            [ {{ m.apelido }} ] {{ m.descricao }}
                            <v-row>
                              <v-col>
                                <v-chip class="mt-2" color="primary">
                                  {{ m.parent ? m.parent.descricao : "" }}
                                </v-chip>
                              </v-col>
                            </v-row>
                          </v-col>

                          <v-col cols="12" sm="2" align="center">
                            <v-btn
                              @click="() => adicionarHabilidade(m)"
                              class="mx-2"
                              fab
                              dark
                              small
                              color="blue"
                            >
                              <v-icon dark> mdi-plus </v-icon>
                            </v-btn>
                          </v-col>
                        </v-list-item>
                      </div>
                    </v-sheet>
                  </v-col>
                  <v-col cols="6">
                    <v-sheet color="white" elevation="1">
                      <v-lazy>
                        <div class="text-start py-2 px-4 text-button blue white--text">
                          Habilidades selecionados
                          <v-icon dark right> mdi-checkbox-marked-circle </v-icon>
                        </div>
                      </v-lazy>

                      <v-divider class="theme--light"></v-divider>

                      <div class="py-2 px-4">
                        <p v-if="objeto_aprendizagem.length === 0">
                          Nenhuma habilidade selecionada
                        </p>

                        <v-list-item
                          class="grey lighten-3 mb-3 pa-md-4"
                          v-for="(m, index) in objeto_aprendizagem"
                          :key="m.id"
                        >
                          <v-btn
                            @click="() => deletarHabilidade(index, m)"
                            class="mx-2"
                            fab
                            dark
                            small
                            color="red"
                          >
                            <v-icon dark> mdi-delete </v-icon>
                          </v-btn>

                          <v-col cols="12" sm="10" class="d-flex flex-column justify-start">
                            [ {{ m.apelido }} ] {{ m.descricao }}
                            <v-row>
                              <v-col>
                                <v-chip class="mt-2" color="primary">
                                  {{ m.parent ? m.parent.descricao : "" }}
                                </v-chip>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-list-item>
                      </div>
                    </v-sheet>
                  </v-col>
                </v-row>
              </v-col>
            </v-card>

            <v-snackbar v-model="showMessage" color="success">
              <h4>{{ mensagem }}</h4>
              <template v-slot:action="{ attrs }">
                <v-btn color="white" text v-bind="attrs" @click="showMessage = false"> x </v-btn>
              </template>
            </v-snackbar>
          </v-dialog>

          <div></div>

          <label for=""> OBJETIVOS DE APRENDIZAGEM E DESENVOLVIMENTO </label>
          <v-card class="pa-md-4 mt-1">
            <div>
              <v-list>
                <v-divider class="theme--light"></v-divider>

                <v-list-item
                  class="grey lighten-3 mb-3 pa-md-4"
                  v-for="(m, index) in objeto_aprendizagem"
                  :key="m.id"
                >
                  <v-btn
                    @click="() => deletarHabilidade(index, m)"
                    class="mx-2"
                    fab
                    dark
                    small
                    color="red"
                  >
                    <v-icon dark> mdi-delete </v-icon>
                  </v-btn>
                  <v-col cols="12" sm="10" class="d-flex flex-column justify-start">
                    [ {{ m.apelido }} ] {{ m.descricao }}
                    <v-row>
                      <v-col>
                        <v-chip class="mt-2" color="primary">
                          {{ m.parent ? m.parent.descricao : "" }}
                        </v-chip>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-list-item>
              </v-list>
            </div>
          </v-card>
        </v-col>

        <v-col>
          <ValidationProvider name="Tematica" rules="required">
            <label for=""> CAMPOS DE EXPERIÊNCIA </label>
            <v-card class="pa-md-4 mt-1">
              <div>
                <v-list>
                  <v-divider class="theme--light"></v-divider>
                  <v-list-item
                    class="grey lighten-3 mb-3 pa-md-4"
                    v-for="m in experiencia"
                    :key="m"
                  >
                    <v-btn
                      @click="() => deletarExperiencia(m)"
                      class="mx-2"
                      fab
                      dark
                      small
                      color="red"
                    >
                      <v-icon dark> mdi-delete </v-icon>
                    </v-btn>
                    {{ m }}
                  </v-list-item>
                </v-list>
              </div>
            </v-card>
          </ValidationProvider>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <ValidationProvider name="Recursos didaticos" rules="required" v-slot="{ errors }">
            <e-label>EIXOS TEMÁTICOS</e-label>
            <v-textarea solo dense v-model="form.eixos_tematicos" :error-messages="errors" />
          </ValidationProvider>

          <ValidationProvider name="Recursos didaticos" rules="required" v-slot="{ errors }">
            <e-label>DIREITOS DE APRENDIZAGEM</e-label>
            <v-textarea solo dense v-model="form.direitos_aprendizagem" :error-messages="errors" />
          </ValidationProvider>

          <ValidationProvider name="Avaliação da aprendizagem" rules="required" v-slot="{ errors }">
            <e-label>METODOLOGIA, RECURSOS E AVALIAÇÃO</e-label>
            <v-textarea
              solo
              dense
              v-model="form.metodologia_recursos_avaliacao"
              :error-messages="errors"
            />
          </ValidationProvider>
        </v-col>
      </v-row>

      <v-col cols="12">
        <v-row>
          <v-btn color="primary" :disabled="submittingForm" :loading="submittingForm" type="submit">
            Enviar Dados
          </v-btn>
          <v-btn color="success" class="ml-3" @click="modal = true">Exportar plano</v-btn>
        </v-row>
      </v-col>
    </form>
    <v-dialog v-model="modal" persistent max-width="800px">
      <v-card>
        <v-card-title class="primary">
          <span style="color: #fff" class="text-h5">Exportar Plano</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <ValidationObserver v-slot="{ handleSubmit }">
              <form
                v-if="show"
                @submit.prevent="handleSubmit(exportarPlano)"
                :disabled="submittingForm"
              >
                <v-row>
                  <v-col class="pt-4 pb-0" cols="12">
                    <e-label class="font-weight-medium">Turma</e-label>
                    <p class="font-weight-regular">
                      <v-select
                        :items="turmas"
                        item-text="turma.descricao"
                        item-value="turma.id"
                        return-object
                        label="Selecione o campo abaixo"
                        v-model="turmaSelecionada"
                        @change="(item) => setGestao(item)"
                      ></v-select>
                    </p>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <e-label class="font-weight-medium"
                      >UNIDADES TEMÁTICAS / PRÁTICAS DE LINGUAGEM</e-label
                    >
                    <p class="font-weight-regular">
                      {{ form.tematica }}
                    </p>
                  </v-col>
                  <v-col cols="12">
                    <e-label class="font-weight-medium">Metodologia</e-label>
                    <p class="font-weight-regular">
                      {{ form.metodologia }}
                    </p>
                  </v-col>
                </v-row>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn style="color: #fff" class="red darken-1" @click="modal = false">
                    Fechar
                  </v-btn>
                  <v-btn
                    :disabled="submittingForm"
                    :loading="submittingForm"
                    type="submit"
                    style="color: #fff"
                    class="green darken-1"
                    >Salvar</v-btn
                  >
                </v-card-actions>
              </form>
            </ValidationObserver>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <e-drawer-planos-lancados
      v-model="openDrawer"
      :gestao_id="parseInt($route.params.gestaoDeAulaId, 10)"
      :bimestre="parseInt(form.bimestre, 10)"
    />
  </main-template>
</template>

<script>
import ELabel from "../../components/ELabel.vue";

export default {
  components: { ELabel },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
    gestaoDeAulaId: {
      type: [String, Number],
      require: true,
    },
  },
  mounted() {
    this.loadData();
  },
  data() {
    return {
      habilidade: null,
      experiencia_: null,
      modalDeletarObjetivo: false,
      mensagem: "",
      carregandoHabilidades: false,
      showMessage: false,
      experiencia: [],
      objeto_aprendizagem: [],
      objetivosAprendizagem: [],
      dialog: false,
      modal: false,
      search: "",
      submittingForm: false,
      form: {
        bimestre: 0,
      },
      openDrawer: false,
      gestaoDeAula: {
        turma: {
          descricao: "",
        },
        disciplina: {
          descricao: "",
        },
      },
      loadPlano: true,
      objectModal: {
        title: "Exportar plano",
        message: "Tem certeza que deseja exportar esta plano?",
      },
      formExportarPlano: {
        bimestre: 0,
      },
      export_gestao: null,
      show: true,
      turmaSelecionada: null,
      turmas: null,
      // setGestao: null,
    };
  },
  methods: {
    async resetPage() {
      Object.assign(this.$data, this.$options.data());
    },
    async deletarExperiencia(experiencia) {
      const pesquisa = experiencia.toString();
      const index = this.experiencia.findIndex((e) => e === pesquisa);
      this.experiencia.splice(index, 1);
      const habilidades = this.objeto_aprendizagem.filter((o) => o.parent.descricao !== pesquisa);
      this.objeto_aprendizagem = habilidades;
    },
    async deletarHabilidade(index, m) {
      const habilidadesExperiencias = this.objeto_aprendizagem.filter(
        (o) => o.parent.descricao === m.parent.descricao
      );
      if (habilidadesExperiencias.length === 1) {
        this.deletarExperiencia(m.parent.descricao);
      }
      this.objeto_aprendizagem.splice(index, 1);
      this.mensagem = "Habilidade deletada com sucesso!";
      this.showMessage = true;
    },
    async adicionarHabilidade(m) {
      const habilidade = this.objeto_aprendizagem.find((o) => o.id === m.id);
      if (habilidade) {
        this.mensagem = "Habilidade já selecionada!";
        this.showMessage = true;
        return;
      }
      this.objeto_aprendizagem.push(m);
      this.mensagem = "Habilidade adicionada com sucesso!";
      this.showMessage = true;

      this.adicionarExperiencia(m.parent.descricao);
    },
    async adicionarExperiencia(experiencia) {
      const experienciaJaExiste = this.experiencia.find((e) => e === experiencia);
      if (!experienciaJaExiste) this.experiencia.push(experiencia);
    },
    async procurarHabilidade() {
      this.carregandoHabilidades = true;
      const data = await this.$services.objetivosAprendizagem.pesquisar(
        this.search,
        this.gestaoDeAula.turma_id
      );
      this.objetivosAprendizagem = data.data.data;
      this.carregandoHabilidades = false;
    },
    async verHistoricoPlanos() {
      this.openDrawer = !this.openDrawer;
    },
    async createOrUpdate() {
      this.$loaderService.open("Carregando dados...");
      try {
        const { gestaoDeAulaId } = this.$route.params;
        const { bimestre } = this.form;
        this.form.instrutorDisciplinaTurma_id = gestaoDeAulaId;
        this.form.bimestre = bimestre;

        this.form.experiencia = this.experiencia.map((e) => e);
        this.form.objeto_aprendizagem = this.objeto_aprendizagem.map((o) => o.id);

        await this.$services.planosBimestraisInfantilService.createOrUpdate(this.form);

        this.$toast.success("Plano Mensal salvo com sucesso!");
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async loadData(value) {
      this.resetPage();
      this.submittingForm = false;
      const { gestaoDeAulaId } = this.$route.params;
      if (value) this.form.bimestre = value;
      try {
        this.$loaderService.open("Carregando dados...");
        this.gestaoDeAula = await this.$services.gestoesDeAulasService.visualizar(gestaoDeAulaId);
        const response = await this.$services.planosBimestraisInfantilService.planoBimestral(
          gestaoDeAulaId,
          this.form.bimestre
        );
        const gestoes = await this.$services.gestoesDeAulasService.listarGestoesProfessor(
          this.gestaoDeAula
        );
        this.turmas = gestoes;
        if (response !== "") {
          this.form = response.plano;
          this.objeto_aprendizagem = response.habilidades;
          this.experiencia = response.plano.experiencia;
        }
        this.$emit("dialogChange", !this.dialog);
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async exportarPlano() {
      this.$loaderService.open("Exportando plano...");
      try {
        // const { gestaoDeAulaId } = this.$route.params;
        const { bimestre } = this.form;

        // this.formExportarPlano.instrutorDisciplinaTurma_id = gestaoDeAulaId;
        this.formExportarPlano.instrutorDisciplinaTurma_id = this.export_gestao;

        this.formExportarPlano.bimestre = bimestre;
        this.formExportarPlano.eixos_tematicos = this.form.eixos_tematicos;
        this.formExportarPlano.direitos_aprendizagem = this.form.direitos_aprendizagem;
        this.formExportarPlano.metodologia_recursos_avaliacao =
          this.form.metodologia_recursos_avaliacao;
        this.formExportarPlano.experiencia = this.experiencia.map((e) => e);
        this.formExportarPlano.objeto_aprendizagem = this.objeto_aprendizagem.map((o) => o.id);

        await this.$services.planosBimestraisInfantilService.createOrUpdate(this.formExportarPlano);

        this.$toast.success("Plano Mensal exportado com sucesso!");

        this.dialog = false;
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    setGestao(item) {
      this.export_gestao = item.id;
    },
  },
};
</script>

<style></style>
