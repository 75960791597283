import { render, staticRenderFns } from "./EResumoAulasSnippetNew.vue?vue&type=template&id=b8a87dfc"
import script from "./EResumoAulasSnippetNew.vue?vue&type=script&lang=js"
export * from "./EResumoAulasSnippetNew.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports