import Axios from "@/plugins/Axios";
import store from "@/store";
import Franquia from "@/Models/Franquia";

export class FranquiasService {
  async syncAll(force = true) {
    // if (this.syncAll.franquias) return this.syncAll.franquias;
    const franquiasList = []; // store.getters["Franquias/franquiasList"];
    if (force || franquiasList.length === 0) {
      const response = await Axios().get("franquias");
      const franquias = response.data.map((franquia) => new Franquia(franquia));
      // store.commit("Franquias/setFranquias", franquias);
      // this.syncAll.franquias = franquias;
      return franquias;
    }
    return franquiasList;
  }

  async getFranquia(id) {
    const response = await Axios().get(`franquias/get-franquia/${id}`);
    // const franquias = response.data.map((franquia) => new Franquia(franquia));
    return response.data;
  }

  async countInfoHome() {
    const response = await Axios().get("franquias/count-info-home");
    const franquias = response.data.map((franquia) => new Franquia(franquia));
    return franquias;
  }

  async listAllSelect() {
    const response = await Axios().get("franquias/list-all-select");
    const franquias = response.data.map((franquia) => new Franquia(franquia));
    return franquias;
  }

  async listAll() {
    const response = await Axios().get("franquias/list-all");
    return response.data;
  }

  async criarFranquia(franquia) {
    const response = await Axios().post("franquias", franquia);
    const novaFranquia = response.data;
    // store.commit("Franquias/adicionarFranquia", novaFranquia);
    return novaFranquia;
  }

  async atualizarFranquia(franquia) {
    const response = await Axios().put(`franquias/${franquia.id}`, franquia);
    const franquiaAtualizada = response.data;
    // store.commit("Franquias/atualizarFranquia", franquiaAtualizada);
    return franquiaAtualizada;
  }

  async deletarFranquia(franquia) {
    const response = await Axios().delete(`franquias/${franquia.id}`);
    const franquiaDeletada = response.data;
    // store.commit("Franquias/deletarFranquia", franquia.id);
    return franquiaDeletada;
  }

  async recuperarFranquia(franquia) {
    return this.atualizarFranquia({ ...franquia, deleted_at: null });
  }

  async qtd() {
    const response = await Axios().get("franquias/qtd");
    return response.data;
  }
}
export default new FranquiasService();
