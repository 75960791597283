import Axios from "@/plugins/Axios";

export class ObjetivosAprendizagem {
  async pesquisar(query, turma_id) {
    const response = await Axios().get(
      `planos-bimestrais-infantil/objetivos-aprendizagem?query=${query}&turma_id=${turma_id}`
    );
    return response;
  }
}

export default new ObjetivosAprendizagem();
