<template>
  <div class="text-center">
    <v-dialog v-model="dialog" persistent width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Selecione um Bimestre </v-card-title>
        <v-card-text>
          <v-col class="d-flex" cols="12" sm="12">
            <v-select
              :items="bimestres"
              item-text="descricao"
              item-value="id"
              v-model="selectBimestre"
              return-object
              label="Selecione um bimestre"
              solo
            ></v-select>
          </v-col>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="text" @click="$emit('dialogChange', !dialog)"> {{ cancelText }} </v-btn>
          <v-btn color="primary" text @click="$emit('changeAction', selectBimestre.id)">
            {{ confirmText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    confirmText: {
      type: String,
      required: true,
    },
    cancelText: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectBimestre: null,
      bimestres: [
        { id: 0, descricao: "1 Bimestre" },
        { id: 1, descricao: "2 Bimestre" },
        { id: 2, descricao: "3 Bimestre" },
        { id: 3, descricao: "4 Bimestre" },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
