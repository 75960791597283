import store from "@/store";
import { $loaderService } from "@/plugins/ELoaderService";

const temCaixaAberto = async (to, from, next) => {
  let currentUser = store.getters["Auth/user"];

  if (!currentUser) {
    await $loaderService.loadBasicDataIfNeeded();
  }

  currentUser = store.getters["Auth/user"];
  // eslint-disable-next-line
  if (!currentUser) {
    next({ name: "login" });
  } else if (currentUser.roleName === "prof-auxiliar") {
    next({ name: "gestoesDeAulasAEE" });
  }
};

export default temCaixaAberto;
