import NewEditEducacaoInclusivaPage from "@/Pages/EducacaoInclusiva/NewEditEducacaoInclusivaPage.vue";
import NewEditFichaDiarioAtendimento from "@/Pages/EducacaoInclusiva/NewEditFichaDiarioAtendimento.vue";
import EditFichaDiarioAtendimento from "@/Pages/EducacaoInclusiva/EditFichaDiarioAtendimento.vue";
import FichaDesenvolvimentotoEspecialFundInicial from "@/Pages/EducacaoInclusiva/FichaDesenvolvimentotoEspecialFundInicial.vue";
import FichaDesenvolvimentotoEspecialInfantil from "@/Pages/EducacaoInclusiva/FichaDesenvolvimentotoEspecialInfantil.vue";
import FichaDesenvolvimentoAnual from "@/Pages/EducacaoInclusiva/FichaDesenvolvimentoAnual.vue";
import FichaDesenvolvimentoMensal from "@/Pages/EducacaoInclusiva/FichaDesenvolvimentoMensal.vue";
import FichaDesenvolvimentoBimestral from "@/Pages/EducacaoInclusiva/FichaDesenvolvimentoBimestral.vue";
import FichaDiarioAtendimento from "@/Pages/EducacaoInclusiva/FichaDiarioAtendimento.vue";

export default [
  {
    path: "/educacao-inclusiva/diario-atendimento/create/:matricula_id/:gestaoDeAulasId",
    name: "educacaoInclusivaDiario.create",
    component: NewEditFichaDiarioAtendimento,
    props: { editing: false },
  },
  {
    path: "/educacao-inclusiva/:gestaoDeAulasId/diario-atendimento/:id/edit/:matricula_id",
    name: "educacaoInclusivaDiario.edit",
    component: NewEditFichaDiarioAtendimento,
    props: { editing: true },
  },
  {
    path: "/educacao-inclusiva/create/:gestao_id",
    name: "educacaoInclusiva.create",
    component: NewEditEducacaoInclusivaPage,
    props: { editing: false },
  },
  {
    path: "/educacao-inclusiva/edit/:matricula_id",
    name: "educacaoInclusiva.edit",
    component: NewEditEducacaoInclusivaPage,
    props: { editing: true },
  },
  {
    path: "/educacao-inclusiva/ficha-fund-ens-final/:matricula_id",
    name: "educacaoInclusiva.fichaDesenvolvimentotoEspecialFundInicial",
    component: FichaDesenvolvimentotoEspecialFundInicial,
  },
  {
    path: "/educacao-inclusiva/ficha-fund-ens-final/:matricula_id",
    name: "educacaoInclusiva.fichaDesenvolvimentotoEspecialInfantil",
    component: FichaDesenvolvimentotoEspecialInfantil,
  },
  {
    path: "/educacao-inclusiva/ficha-desen-anual/:matricula_id/:gestaoDeAulasId",
    name: "educacaoInclusiva.fichaDesenvolvimentoAnual",
    component: FichaDesenvolvimentoAnual,
  },
  {
    path: "/educacao-inclusiva/ficha-desen-mensal/:matricula_id",
    name: "educacaoInclusiva.fichaDesenvolvimentoMensal",
    component: FichaDesenvolvimentoMensal,
  },
  {
    path: "/educacao-inclusiva/ficha-desen-bimestral/:matricula_id/:gestaoDeAulasId",
    name: "educacaoInclusiva.fichaDesenvolvimentoBimestral",
    component: FichaDesenvolvimentoBimestral,
  },
  {
    path: "/educacao-inclusiva/ficha-diario-atend/:matricula_id/:gestaoDeAulasId",
    name: "educacaoInclusiva.fichaDiarioAtendimento",
    component: FichaDiarioAtendimento,
  },
];
