<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="() => this.$router.go(-1)"
            :disabled="submittingForm"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          {{ editing ? "Editando" : "Criando" }} Diário Atendimento
        </h1>
      </v-col>
    </v-row>
    <v-col>
      <v-card>
        <v-card-text>
          <h4>Turma: {{ gestaoDeAula.turma.descricao }}</h4>
          <h4>Aluno: {{ matricula?.aluno ? matricula?.aluno.nomecompleto : "- - -" }}</h4>
        </v-card-text>
        <v-divider class="mx-4"></v-divider>
        <v-card-text>
          Professor:
          {{ gestaoDeAula.professor ? gestaoDeAula.professor.nome : "[NÃO INFORMADO]" }}
        </v-card-text>
      </v-card>
    </v-col>
    <v-col>
      <v-row>
        <v-col class="pt-0 pb-0" cols="6">
          <v-dialog ref="dialog" v-model="modal" :return-value.sync="data" persistent width="290px">
            <template v-slot:activator="{ on, attrs }">
              <e-label>Selecione uma data</e-label>
              <v-text-field
                v-model="aulaSelecionada.data"
                persistent-hint
                label="Escolha uma data"
                readonly
                v-bind="attrs"
                v-on="on"
                dense
                solo
              ></v-text-field>
            </template>
            <v-date-picker v-model="data" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal = false"> Cancel </v-btn>
              <v-btn text color="primary" @click="$refs.dialog.save(data)"> OK </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>

        <v-col class="pt-0 pb-0" cols="6">
          <ValidationProvider name="Horário da aula" rules="required" v-slot="{ errors }">
            <e-label>Selecione um horário</e-label>
            <e-autocomplete
              :items="horarios"
              :error-messages="errors"
              label="Selecione uma opção"
              :item-text="(item) => item.descricao"
              :item-value="(item) => item.id"
              v-model="horarioSelecionado"
              return-object
              dense
            />
          </ValidationProvider>
        </v-col>
      </v-row>
    </v-col>

    <v-col>
      <ValidationObserver ref="observer" v-slot="{ invalid, validate }">
        <form @submit.prevent="handleSubmit(createOrUpdate)">
          <v-row v-for="(item, index) in $constants.fichaEducacaoEspecialDiario" :key="index">
            <v-col cols="12">
              <ValidationProvider :name="item.descricao" rules="required" v-slot="{ errors }">
                <e-label>{{ item.descricao }}</e-label>
                <v-textarea
                  solo
                  dense
                  v-model="aulaSelecionada[item.form]"
                  :error-messages="errors"
                  @input="validate()"
                />
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-col cols="12">
            <v-row>
              <v-btn
                @click="createOrUpdate"
                color="primary"
                :disabled="submittingForm || invalid"
                :loading="submittingForm"
                type="submit"
                >Enviar Dados</v-btn
              >
            </v-row>
          </v-col>
        </form>
      </ValidationObserver>
    </v-col>
  </main-template>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { required, min, max } from "vee-validate/dist/rules";
import ELabel from "../../components/ELabel.vue";

export default {
  components: { ELabel, ValidationObserver, ValidationProvider },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
    gestaoDeAulaId: {
      type: [String, Number],
      require: true,
    },
  },
  mounted() {
    this.loadBasicData();
  },
  data() {
    return {
      horarioSelecionado: null,
      aulaSelecionada: {
        data: null,
        horario_aee_id: null,
      },
      form: {
        data: null,
        horario_aee_id: null,
      },
      gestaoDeAula: {
        turma: {
          descricao: "",
        },
        disciplina: {
          descricao: "",
        },
      },
      ficha: null,
      matricula: null,
      gestao: null,
      modal: false,
      menu: false,
      horarios: [],
      submittingForm: false,
      data: null,
    };
  },
  watch: {
    data(value) {
      const data_old = new Intl.DateTimeFormat("pt-BR", {
        timeZone: "UTC",
      }).format(new Date(value));
      this.aulaSelecionada.data = data_old.replaceAll("/", "/");
    },
    horarioSelecionado(newVal) {
      this.form.horario_aee_id = newVal?.id || null;
    },
  },
  computed: {
    isFormValid() {
      return this.$constants.fichaEducacaoEspecialDiario.every((item) => {
        return this.form[item.form] !== null && this.form[item.form] !== "";
      });
    },
  },

  methods: {
    formatDate(dateString) {
      const [day, month, year] = dateString.split("/");
      return `${year}-${month}-${day}`;
    },

    async createOrUpdate() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) return;
      this.submittingForm = true;
      try {
        const { id } = this.$route.params;
        const { gestaoDeAulasId } = this.$route.params;
        this.form.id = id;
        this.form.gestao_id = gestaoDeAulasId;
        this.form.data = this.formatDate(this.aulaSelecionada.data);
        this.form.matricula_id = this.matricula?.id;
        this.aulaSelecionada.horario_aee_id = this.horarioSelecionado
          ? this.horarioSelecionado.id
          : null;
        Object.assign(this.form, this.aulaSelecionada);

        await this.$services.educacaoInclusivaService.lancarFichaDeDiarioAtendimentoEdit(this.form);
        if (id) {
          this.$toast.info("Diário de atendimento editado!");
        } else {
          this.$toast.info("Diário de atendimento criado!");
        }
      } catch (error) {
        this.$handleError(error);
      } finally {
        this.$router.go(-1);
        this.submittingForm = false;
      }
    },

    areAllFieldsFilled() {
      return this.$constants.fichaEducacaoEspecialDiario.every((item) => {
        return this.form[item.form] !== null && this.form[item.form] !== "";
      });
    },
    async updateButtonState() {
      await this.$nextTick();
      this.$refs.observer.validate();
    },

    async getFicha() {
      try {
        this.$loaderService.open("Carregando dados...");
        const { id } = this.$route.params;
        const { gestaoDeAulasId } = this.$route.params;
        const { matricula_id } = this.$route.params;
        const response =
          await this.$services.educacaoInclusivaService.carregarFichaDeDiarioAtendimento(
            matricula_id,
            gestaoDeAulasId
          );

        this.gestaoDeAula = response.gestao;
        this.matricula = response.matricula;
        this.horarios = response.horarios.filter((horario) => horario.descricao);

        const aulaSelecionada =
          await this.$services.educacaoInclusivaService.carregarFichaDeDiarioAtendimentoEdit(
            parseInt(id, 10)
          );

        // Aqui o horarioSelecionado deve ser definido
        this.horarioSelecionado =
          this.horarios.find((h) => h.id === aulaSelecionada.horario_aee_id) || null;

        return aulaSelecionada;
      } catch (error) {
        this.$handleError(error);
      } finally {
        this.$loaderService.close();
      }
    },

    async loadBasicData() {
      try {
        this.$loaderService.open("Carregando dados...");
        this.aulaSelecionada = await this.getFicha();

        if (this.aulaSelecionada.data) {
          this.aulaSelecionada.data = this.aulaSelecionada.data.split("-").reverse().join("/");
        }
        this.$forceUpdate();
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
  },
};
</script>

<style></style>
