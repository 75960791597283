<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="() => this.$router.go(-1)"
            :disabled="submittingForm"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          {{ editing ? "Editando" : "Criando" }} Diário Atendimento
        </h1>
      </v-col>
    </v-row>
    <v-col>
      <v-card>
        <v-card-title>DIÁRIO DE DESENVOLVIMENTO</v-card-title>
        <v-card-text>
          <h4>Turma: {{ form.matricula ? form.matricula.turma.descricao : "- - -" }}</h4>
          <h4>Aluno: {{ form.matricula ? form.matricula.aluno.nomecompleto : "- - -" }}</h4>
        </v-card-text>
        <v-divider class="mx-4"></v-divider>
        <v-card-title>Professores (diários)</v-card-title>
        <v-card-text>
          <div>Professor(a): {{ form.gestao ? form.gestao.professor.nome : "- - -" }}</div>
        </v-card-text>
      </v-card>
    </v-col>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(createOrUpdate)" :disabled="submittingForm">
        <v-col>
          <v-row>
            <v-col class="pt-0 pb-0" cols="6">
              <e-label>Selecione uma data</e-label>
              <!-- <v-text-field :error-messages="errors" v-model="form.data" dense solo /> -->
              <e-date-picker v-model="form.data" placeholder="dd/mm/yyyy"></e-date-picker>
            </v-col>
            <!-- <v-col class="pt-0 pb-0" :cols="12" :sm="4">
          <ValidationProvider name="Data" rules="required">
            <e-label>Data </e-label>
            <e-date-picker v-model="form.data" />
          </ValidationProvider>
        </v-col> -->
            <v-col class="pt-0 pb-0" cols="6">
              <ValidationProvider name="Horário da aula" rules="required">
                <e-label>Selecione um horário</e-label>
                <e-autocomplete
                  :items="horarios"
                  label="Selecione uma opção"
                  :item-text="(item) => item.descricao"
                  v-model="form.horario_aee_id"
                  :item-value="(item) => item.id"
                  dense
                />
              </ValidationProvider>
            </v-col>
          </v-row>
        </v-col>

        <v-col>
          <v-row v-for="(item, index) in $constants.fichaEducacaoEspecialDiario" :key="index">
            <v-col cols="12">
              <e-label>{{ item.descricao }}</e-label>
              <v-textarea solo dense v-model="form[item.form]" />
            </v-col>
          </v-row>
          <v-col cols="12">
            <v-row>
              <v-btn
                color="primary"
                :disabled="submittingForm"
                :loading="submittingForm"
                type="submit"
                >Enviar Dados</v-btn
              >
            </v-row>
          </v-col>
        </v-col>
      </form>
    </ValidationObserver>
  </main-template>
</template>

<script>
import ELabel from "../../components/ELabel.vue";

export default {
  components: { ELabel },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
    gestaoDeAulaId: {
      type: [String, Number],
      require: true,
    },
  },
  mounted() {
    this.loadBasicData();
  },
  data() {
    return {
      form: {
        data: null,
        horario_aee_id: {
          descricao: "Escolha um horario",
        },
      },
      modal: false,
      menu: false,
      horarios: [],
      matricula: null,
      gestao_de_aulas: [],
      submittingForm: false,
      aulaSelecionada: {},
    };
  },
  methods: {
    async createOrUpdate() {
      this.submittingForm = true;
      this.$loaderService.open("Carregando dados...");
      try {
        const ficha_id = this.$route.params;
        this.form.ficha_id = ficha_id;
        this.$toast.info("Diário editado com sucesso!");
        await this.$services.educacaoInclusivaService.lancarFichaDeDiarioAtendimentoEdit(this.form);
      } catch (error) {
        this.$handleError(error);
      }
      this.$router.push({ name: "gestoesDeAulasAEE" });
      this.submittingForm = false;
    },

    async loadBasicData() {
      this.$loaderService.open("Carregando dados...");
      try {
        const ficha_id = this.$route.params;
        const response =
          await this.$services.educacaoInclusivaService.carregarFichaDeDiarioAtendimentoEdit(
            parseInt(ficha_id.id, 10)
          );
        this.form = response.data.ficha ? response.data.ficha : {};
        this.lancarRelatorio = this.$constants.fichaEducacaoEspecialDiario;
        this.data = response?.data;
        this.horarios = response.data.horarios;
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
  },
};
</script>

<style></style>
