<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-chip color="green" label small v-bind="attrs" v-on="on"
        >{{ aulas_confirmadas }}/{{ aulas_aguardando }}</v-chip
      >
    </template>
    <span>
      <div>Aulas confirmadas: {{ aulas_confirmadas }}</div>
      <div>Aulas aguardando confirmação: {{ aulas_aguardando }}</div>
    </span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    aulas_confirmadas: {
      type: Number,
      required: true,
    },
    aulas_aguardando: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style></style>
