<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn color="primary" x-small text @click="goBack">
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          Lançamentos AEE
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" v-if="turma">
        <v-card color="primary" class="mb-5" outlined>
          <v-card-title> Turma: {{ turma.descricao }}</v-card-title>
          <v-card-subtitle class="font-weight-medium">
            <div>
              Professor(a): <strong>{{ professor.nome || "- - -" }}</strong>
            </div>
            <div>
              Total de alunos: <strong>{{ matriculas.length || "- - -" }}</strong>
            </div>
            <div>
              Tipo de atendimento: <strong>{{ turma.tipo_de_atendimento || "- - -" }}</strong> |
              Tipo de ensino:
              <strong>{{ turma.curso?.descricao || "- - -" }}</strong>
            </div>
          </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
    <v-card class="mt-3">
      <template>
        <v-tabs v-model="tab_atual" background-color="primary" dark>
          <v-tab v-for="tab of tabs" :key="tabs.indexOf(tab)">{{ tab.descricao }}</v-tab>
        </v-tabs>
        <div class="text-right">
          <v-btn color="primary" class="ml-4 mt-4 mr-4 white--text" @click="gerarRelatorio">
            Lista da turma
            <v-icon right dark> mdi-file </v-icon>
          </v-btn>
        </div>
        <v-col class="pt-0">
          <v-data-table
            :headers="table.headers"
            :items="matriculas"
            :loading="carregandoGestoesDeAulas"
            :items-per-page="matriculas.length"
            hide-default-footer
            no-data-text="Não foram encontrádos vínculos para você, por favor entre em contato com a secretaria/coordenação para que lhe adicionem acesso às turmas e disciplinas"
            class="elevation-1"
          >
            <template v-slot:body="{ items }">
              <tbody name="scroll-x-transition" is="transition-group" duration="200">
                <tr
                  v-for="(matricula, index) in items"
                  :key="matricula.id"
                  :class="!matricula.aluno ? 'accent' : ''"
                >
                  <td width="20">{{ index + 1 }}</td>
                  <td class="font-weight-medium text-left">
                    <div>
                      {{
                        matricula.aluno
                          ? matricula.aluno.nomecompleto
                          : `[ALUNO POSSIVELMENTE DELETADO] MATRICULA: ${matricula.id}`
                      }}
                    </div>
                    <div>
                      <small>
                        {{ matricula.aluno ? matricula.aluno.codigo_inep : "- - -" }} /
                        {{ matricula.aluno ? matricula.aluno.codigo : "- - -" }}
                      </small>
                    </div>
                  </td>
                  <td>
                    <div class="text-center">
                      <v-menu :close-on-content-click="false" :nudge-width="100" offset-x>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn x-small color="primary" dark v-bind="attrs" v-on="on">
                            AÇÕES
                          </v-btn>
                        </template>

                        <v-card class="mx-auto" max-width="300">
                          <v-list>
                            <v-list-item-group color="primary">
                              <v-list-item
                                :to="{
                                  name: 'educacaoInclusiva.fichaDiarioAtendimento',
                                  params: {
                                    matricula_id: matricula.id,
                                    gestaoDeAulasId: gestao_id,
                                  },
                                }"
                              >
                                <v-list-item-icon>
                                  <v-icon small>fa fa-book</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>Diário de atendimento</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item
                                :to="{
                                  name: 'educacaoInclusiva.fichaDesenvolvimentoAnual',
                                  params: {
                                    matricula_id: matricula.id,
                                    gestaoDeAulasId: gestao_id,
                                  },
                                }"
                              >
                                <v-list-item-icon>
                                  <v-icon small>fa fa-book</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>Desenvolvimento anual</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item
                                :to="{
                                  name: 'educacaoInclusiva.fichaDesenvolvimentoBimestral',
                                  params: {
                                    matricula_id: matricula.id,
                                    gestaoDeAulasId: gestao_id,
                                  },
                                }"
                              >
                                <v-list-item-icon>
                                  <v-icon small>fa fa-book</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>Desenvolvimento bimestral</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item
                                @click="() => RelatorioDiarioAtendimento(matricula.id, gestao_id)"
                              >
                                <v-list-item-icon>
                                  <v-icon small>fa fa-file-pdf</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>Gerar diário atendimento</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item
                                @click="() => openModalSelectSemestre(matricula.id, gestao_id)"
                              >
                                <v-list-item-icon>
                                  <v-icon small>fa fa-file-pdf</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>Gerar relatório anual</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item
                                @click="() => openModalSelectMonths(matricula.id, gestao_id)"
                              >
                                <v-list-item-icon>
                                  <v-icon small>fa fa-file-pdf</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>Gerar relatório bimestral</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>
                        </v-card>
                      </v-menu>
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-col>
      </template>
    </v-card>
    <e-select-bimestre-modal
      @dialogChange="dialog = $event"
      :dialog="dialog"
      confirmText="Gerar relatório"
      cancelText="Fechar"
      @changeAction="relatorioEspecialDesenvolvimentoBimestral"
    ></e-select-bimestre-modal>
    <e-select-semestre-modal
      @dialogChange="dialogSemestre = $event"
      :dialog="dialogSemestre"
      confirmText="Gerar relatório"
      cancelText="Fechar"
      @changeAction="gerarRelatorioAnual"
    ></e-select-semestre-modal>
  </main-template>
</template>

<script>
export default {
  data() {
    return {
      turma: null,
      professor: null,
      gestao_id: null,
      matriculas: [],
      tabs: [],
      tab_atual: 0,
      table: {
        headers: [
          { text: "#", value: "id" },
          { text: "Aluno", value: "nomeFranquia" },
          { text: "", value: "actions", sortable: false, width: "200px" },
        ],
      },
      carregandoGestoesDeAulas: false,
      dialog: false,
      dialogSemestre: false,
    };
  },
  async mounted() {
    await this.loadBasicData();
  },
  methods: {
    openModalSelectSemestre(matricula_id, gestao_id) {
      this.matricula_id = matricula_id;
      this.gestao_id = gestao_id;
      this.dialogSemestre = !this.dialogSemestre;
    },
    openModalSelectMonths(matricula_id, gestao_id) {
      this.matricula_id = matricula_id;
      this.gestao_id = gestao_id;
      this.dialog = !this.dialog;
    },
    async gerarRelatorio() {
      this.$loaderService.open("Carregando dados...");
      await this.$services.relatoriosService.listarTurma(parseInt(this.$route.params.turmaId, 10));
      this.$loaderService.close();
    },
    async RelatorioDiarioAtendimento(matricula_id, gestao_id) {
      this.$loaderService.open("Carregando dados...");
      await this.$services.relatoriosService.relatorioDiarioAtendimento(
        parseInt(matricula_id, 10),
        parseInt(gestao_id, 10)
      );
      this.$loaderService.close();
    },
    async loadBasicData() {
      this.$loaderService.open("Carregando dados...");
      try {
        const payload = await this.$services.gestoesDeAulasAEEService.visualizarTurma(
          parseInt(this.$route.params.gestaoDeAulasId, 10)
        );
        this.gestao_id = payload.id;
        this.turma = payload.turma;
        this.professor = payload.professor;
        this.matriculas = payload.turma.matriculas || [];
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    gerarRelatorioAnual(semestre) {
      this.$loaderService.open("Carregando dados...");
      this.$services.relatoriosService.relatorioEspecialDesenvolvimentoAnual(
        this.matricula_id,
        this.gestao_id,
        semestre
      );
      this.$loaderService.close();
    },
    async relatorioEspecialDesenvolvimentoBimestral(bimestre) {
      this.$loaderService.open("Carregando dados...");
      try {
        await this.$services.relatoriosService.relatorioEspecialDesenvolvimentoBimestral(
          this.matricula_id,
          this.gestao_id,
          bimestre
        );
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped></style>
