<template>
  <main-template>
    <v-row>
      <v-col>
        <span class="text-h4">Franquias </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pt-0 pb-0">
        <div class="d-flex mb-2 justify-space-between">
          <v-btn color="success" @click="criarFranquia" class="mr-2">Criar Franquia</v-btn>
          <v-text-field
            v-model="search"
            class="ml-2 search-input"
            append-icon="mdi-magnify"
            label="Pesquisar..."
            solo
            autofocus
            dense
            single-line
            hide-details
          />
        </div>
      </v-col>
      <v-col class="pt-0">
        <v-data-table
          :headers="table.headers"
          :items="franquiasList"
          :loading="carregandoFranquias"
          :items-per-page="10"
          :custom-filter="dataTableFilter"
          :search="search"
          class="elevation-1"
        >
          <template v-slot:body="{ items }">
            <tbody name="scroll-x-transition" is="transition-group" duration="150">
              <tr
                color="primary"
                :class="franquia.estaDeletado ? ['deleted'] : []"
                v-for="franquia of items"
                :key="franquia.id"
              >
                <td>{{ franquia.id }}</td>
                <td>{{ franquia.codigo }}</td>
                <td>{{ franquia.descricao }}</td>
                <td>{{ franquia.cidade }}</td>
                <!-- <td>{{ franquia.contexto }}</td> -->
                <td>
                  <v-tooltip v-if="!franquia.estaDeletado" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="blue-grey lighten-3"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => editarFranquia(franquia)"
                      >
                        <v-icon small>fa fa-edit </v-icon>
                      </v-btn>
                    </template>
                    <span>Editar franquia</span>
                  </v-tooltip>
                  <v-tooltip v-if="!franquia.estaDeletado" color="error" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="error"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => deletarFranquia(franquia)"
                      >
                        <v-icon small>fa fa-trash </v-icon>
                      </v-btn>
                    </template>
                    <span>Apagar franquia</span>
                  </v-tooltip>
                  <v-tooltip v-if="franquia.estaDeletado" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="info"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => recuperarFranquia(franquia)"
                      >
                        <v-icon small>fa fa-sync </v-icon>
                      </v-btn>
                    </template>
                    <span>Recuperar franquia</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </main-template>
</template>

<script>
import { dataTableFilter } from "@/plugins/searchQuery";

export default {
  data() {
    return {
      dataTableFilter,
      search: "",
      table: {
        headers: [
          { text: "#", value: "id" },
          { text: "Código", value: "codigo" },
          { text: "Unidade", value: "descricao" },
          { text: "Cidade", value: "cidade" },
          // { text: "Contexto", value: "contexto" },
          { text: "Ações", value: "actions", sortable: false },
        ],
      },
      franquiasList: [],
      carregandoFranquias: false,
    };
  },
  async mounted() {
    this.franquiasList = await this.$services.franquiasService.syncAll();
  },
  methods: {
    criarFranquia() {
      this.$router.push({ name: "franquias.create" });
    },
    editarFranquia(franquia) {
      this.$router.push({ name: "franquias.edit", params: { franquia_id: franquia.id } });
      // return franquia;
    },
    async deletarFranquia(franquia) {
      this.$modals.danger({
        title: "Cuidado!",
        message: "Tem certeza que deseja apagar esta franquia?",
        confirmationCode: franquia.id,
        confirmationMessage: `Por favor, digite <strong>${franquia.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Deletando a Franquia");
              try {
                await this.$services.franquiasService.deletarFranquia(franquia);
                this.$toast.success("Franquia deletada com sucesso");
                this.$forceUpdate();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              // text: true,
              small: true,
            },
          },
        ],
      });
    },
    recuperarFranquia(franquia) {
      this.$modals.warn({
        title: "Atenção!",
        message: "Tem certeza que deseja recuperar esta franquia?",
        confirmationCode: franquia.id,
        confirmationMessage: `Por favor, digite <strong>${franquia.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Recuperando a Franquia");
              try {
                await this.$services.franquiasService.recuperarFranquia(franquia);
                this.$toast.success("Franquia recuperada com sucesso");
                this.$forceUpdate();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              // text: true,
              small: true,
            },
          },
        ],
      });
    },
  },
};
</script>

<style></style>
