import Index from "@/Pages/GestaoDeAulasAEE/index.vue";
import NewCreateGestaoDeAEE from "@/Pages/GestaoDeAulasAEE/NewCreateGestaoDeAEE.vue";
import relatorioAEE from "@/Pages/GestaoDeAulasAEE/relatorioAEE.vue";

export default [
  {
    path: "/gestoes-de-aulas-aee",
    name: "gestoesDeAulasAEE",
    component: Index,
  },
  {
    path: "/gestoes-de-aulas-aee-create",
    name: "gestoesDeAulasAEE.newEdit",
    component: NewCreateGestaoDeAEE,
    props: { editing: false },
  },
  {
    path: "/gestoes-de-aulas-aEE/edit/:gestaoDeAulasId",
    name: "gestoesDeAulasAEE.edit",
    component: NewCreateGestaoDeAEE,
    props: { editing: true },
  },
  {
    path: "/gestoes-de-aulas-aee-relatorio/:gestaoId",
    name: "gestoesAEERelatorio.newEdit",
    component: relatorioAEE,
  },
];
