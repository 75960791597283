<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="() => this.$router.go(-1)"
            :disabled="submittingForm"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title
            >Plano de Desenvolvimento PsicoEducacional Individualizado (PDPI) Coordenação de
            Educação Especial</v-card-title
          >
          <v-card-text>
            <h4>Turma: {{ gestao?.turma ? gestao?.turma.descricao : "- - -" }}</h4>
            <h4>Aluno: {{ matricula?.aluno ? matricula?.aluno.nomecompleto : "- - -" }}</h4>
          </v-card-text>
          <v-divider class="mx-4"></v-divider>
          <v-card-title>Professores (diários)</v-card-title>
          <v-card-text>
            <div>Professor(a): {{ gestao?.professor ? gestao?.professor.nome : "- - -" }}</div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-card-text>
      <v-row class="mt-5">
        <v-select
          :items="bimestres"
          item-text="descricao"
          item-value="id"
          v-model="bimestreSelecionado"
          return-object
          label="Selecione um bimestre"
          solo
        ></v-select>
      </v-row>
    </v-card-text>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(createOrUpdate)" :disabled="submittingForm">
        <v-card>
          <v-card-title>ORGANIZAÇÃO DO ATENDIMENTO</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <ValidationProvider name="Período do Plano" rules="required" v-slot="{ errors }">
                  <e-label>Período do Plano:</e-label>
                  <v-text-field v-model="form.periodo_do_plano" :error-messages="errors" />
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <ValidationProvider
                  name="Duração do Atendimento"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <e-label>Duração do Atendimento: </e-label>
                  <v-text-field v-model="form.duracao_do_atendimento" :error-messages="errors" />
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <e-label>Frequência do Atendimento Semanal:</e-label>
                <v-radio-group v-model="form.frequencia" row>
                  <v-radio label="1 vez" value="1_vez" />
                  <v-radio label="2 vezes" value="2_vezes" />
                  <v-radio label="3 vezes" value="3_vezes" />
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <e-label>Tipo de atendimento:</e-label>
                <v-radio-group v-model="form.tipo_atendimento" row>
                  <v-radio label="Individual" value="individual" />
                  <v-radio label="Coletivo" value="coletivo" />
                  <v-radio label="Coletivo e Individual" value="coletivo_individual" />
                </v-radio-group>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card>
          <v-card-title>PROPOSTA PEDAGÓGICA</v-card-title>
          <v-card-text>
            <v-row
              v-for="(item, index) in $constants.fichaEducacaoEspecialBimestralProposta"
              :key="index"
            >
              <v-col cols="12">
                <ValidationProvider :name="item.descricao" rules="required" v-slot="{ errors }">
                  <e-label>{{ item.descricao }}</e-label>
                  <v-textarea solo dense v-model="form[item.form]" :error-messages="errors" />
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-col cols="12">
          <v-row>
            <v-btn
              color="primary"
              :disabled="submittingForm"
              :loading="submittingForm"
              type="submit"
              >Enviar Dados</v-btn
            >
          </v-row>
        </v-col>
      </form>
    </ValidationObserver>
  </main-template>
</template>

<script>
import ELabel from "../../components/ELabel.vue";

export default {
  components: { ELabel },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
    gestaoDeAulaId: {
      type: [String, Number],
      require: true,
    },
  },
  mounted() {
    this.loadBasicData();
  },
  watch: {
    bimestreSelecionado() {
      this.loadBasicData();
    },
  },
  data() {
    return {
      submittingForm: false,
      gestao: null,
      form: {
        periodo_do_plano: "",
        duracao_do_atendimento: "",
        frequencia: "",
        tipo_atendimento: "",
      },
      painel: null,
      matricula: null,
      gestao_de_aulas: [],
      bimestres: [
        { id: 0, descricao: "1 Bimestre" },
        { id: 1, descricao: "2 Bimestre" },
        { id: 2, descricao: "3 Bimestre" },
        { id: 3, descricao: "4 Bimestre" },
      ],
      bimestreSelecionado: {
        id: 0,
      },
    };
  },
  methods: {
    async createOrUpdate() {
      this.$loaderService.open("Carregando dados...");
      try {
        const { matricula_id } = this.$route.params;
        const { gestaoDeAulasId } = this.$route.params;
        this.form.matricula_id = matricula_id;
        this.form.gestao_id = gestaoDeAulasId;
        this.form.bimestre_id = this.bimestreSelecionado.id;
        this.form.matricula_id = this.matricula.id;
        this.$toast.info("Relatório salvo com sucesso!");
        await this.$services.educacaoInclusivaService.lancarFichaDeDesenvolvimentoBimestral(
          this.form
        );
      } catch (error) {
        this.$handleError(error);
      }
      this.$router.push({ name: "relatorios.visualizarDiarioAee" });
    },

    async loadBasicData() {
      this.$loaderService.open("Carregando dados...");
      try {
        const { matricula_id } = this.$route.params;
        const { gestaoDeAulasId } = this.$route.params;
        const response =
          await this.$services.educacaoInclusivaService.carregarrFichaDeDesenvolvimentoBimestral(
            matricula_id,
            gestaoDeAulasId,
            this.bimestreSelecionado.id
          );
        this.form = response.ficha ? response.ficha : {};
        this.matricula = response.matricula;
        this.gestao = response.gestao;
        this.gestao_de_aulas = response.gestao_de_aulas;
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
  },
};
</script>

<style></style>
