<template>
  <div class="template-wrapper" id="stopped">
    <div>
      <e-left-menu />
    </div>

    <div class="main-content">
      <v-app-bar
        elevate-on-scroll
        width="100%"
        color="#fbbb14"
        style="overflow: hidden"
        class="justify-sm-space-between"
      >
        <v-btn icon @click="toggleMenu">
          <v-icon> mdi mdi-menu </v-icon>
        </v-btn>

        <span class="text-subtitle-1 d-none d-sm-block black--text" v-if="!hideFranquia">
          {{ user ? user?.franquia?.descricao : "..." }}
        </span>

        <v-spacer></v-spacer>

        <e-notificacoes-list></e-notificacoes-list>

        <v-list color="transparent" max-width="260px" class="pa-0">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item v-bind="attrs" v-on="on">
                <v-list-item-avatar>
                  <e-user-avatar current />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="black--text">
                    {{ (user ? user.name : "Carregando...") | truncate(15) }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <e-user-role :value="user" link />
                  </v-list-item-subtitle> </v-list-item-content
                ><v-list-item-action>
                  <v-icon>mdi-menu-down</v-icon>
                </v-list-item-action>
              </v-list-item>
            </template>

            <v-list dense>
              <v-list-item dense link @click="openSelecionarFranquiaModal = true">
                <v-list-item-avatar>
                  <v-icon small>fa fa-cogs</v-icon>
                </v-list-item-avatar>
                <v-list-item-title>Alterar unidade escolar</v-list-item-title>
              </v-list-item>
              <v-list-item dense link @click="openAtualizarSenhaModal = true">
                <v-list-item-avatar>
                  <v-icon small>fa fa-key</v-icon>
                </v-list-item-avatar>
                <v-list-item-title>Alterar senha</v-list-item-title>
              </v-list-item>
              <v-list-item dense link :to="{ name: 'logout' }">
                <v-list-item-avatar>
                  <v-icon small>mdi mdi-logout</v-icon>
                </v-list-item-avatar>
                <v-list-item-title>Sair</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list>
      </v-app-bar>

      <v-dialog v-model="modalOcioso" persistent max-width="500">
        <!-- <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" dark v-bind="attrs" v-on="on"> Open Dialog </v-btn>
        </template> -->
        <v-card>
          <v-card-text class="text-center text-h5 pt-4 font-weight-bold">
            Sua sessão está proxima de expirar!
          </v-card-text>
          <v-card-text class="text-center"
            >Esta faltando

            <v-chip class="ma-2" color="orange" text-color="white">
              {{ this.tempoFormatado }}
            </v-chip>

            segundos.</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="
                limparIntervalo(idInterval);
                dataInicial();
                temporizador();
                modalOcioso = false;
              "
            >
              Permanecer logado
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-system-bar color="yellow lighten-5">
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-btn
          v-show="botaoAlerta === true"
          x-small
          class="ma-10"
          outlined
          color="primary"
          @click="novoAlerta = true"
        >
          <v-icon color="secondary">campaign</v-icon> Novo Alerta</v-btn
        >
        <span class="caption text--black mr-2">Tempo de sessão</span>
        <v-icon>mdi-timer-outline</v-icon>
        <span class="caption text--black mr-2">{{ tempoFormatado }}</span>
        <!-- <v-slider
          v-model="ex1.val"
          :color="ex1.color"
          :track-color="ex1.color"
          class="mt-6 hover caption"
          readonly
        ></v-slider> -->
      </v-system-bar>

      <e-mensagem-alerta
        @bloquearBotaoNovo="block()"
        @situacaoBtnAlerta="
          ($event) => {
            (botaoAlerta = $event), (novoAlerta = false);
          }
        "
        :novo="novoAlerta"
        :texto="textoMensagemAlerta"
      />

      <v-container>
        <slot />
        <v-row style="min-height: 90px">
          <v-col>
            <span></span>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <e-change-password-modal v-model="openAtualizarSenhaModal" />
    <e-selecionar-franquia-modal v-model="openSelecionarFranquiaModal" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EChangePasswordModal from "../../../components/EChangePasswordModal.vue";
import ENotificacoesList from "../../../components/Autorizacao/ENotificacoesList.vue";
import EMensagemAlerta from "../../../components/EMensagemAlerta.vue";

const exibirFinanceiro = process.env.VUE_APP_ATIVAR_FINANCEIRO === "true";
export default {
  name: "MainTemplate",
  components: {
    EChangePasswordModal,
    ENotificacoesList,
    EMensagemAlerta,
  },
  props: {
    hideFranquia: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters("Auth", ["user"]),
    ...mapGetters("Franquias", ["franquias"]),
    ...mapGetters("MainTemplate", ["menuOpen"]),
  },
  mounted() {
    // this.$Tawk.$setOnLoadFunction(() => this.$Tawk.$showWidget());
    this.dataInicial();
    this.temporizador();

    const para1 = document.getElementById("para1");
    this.animate(para1);
  },
  data() {
    return {
      permitirEdicaoAlert: false,
      botaoAlerta: true,
      novoAlerta: false,
      textoMensagemAlerta: null,
      idInterval: null,
      modalOcioso: false,
      active: false,
      tempo: null,
      tempoFormatado: null,
      durabilidadeDaVidaDoUsuario: 0,
      ex1: { label: "Life", val: 100, color: "orange" },
      selectedItem: 0,
      openAtualizarSenhaModal: false,
      openSelecionarFranquiaModal: false,
      exibirFinanceiro,
      menu: {
        items: [
          {
            icon: "fa fa-home",
            title: "Início",
            exibir: true,
            children: [{ title: "Ir para Início", to: { name: "home" }, icon: "" }],
          },
          {
            icon: "fa fa-user-graduate",
            title: "Matrículas",
            exibir: true,
            children: [
              { title: "Ver Matrículas", to: { name: "matriculas" }, icon: "" },
              // { title: "Adicionar matricula", to: "#", icon: "" },
              { title: "Ver Alunos", to: { name: "alunos" }, icon: "" },
              { title: "Pesquisa avançada", to: { name: "alunos-pesquisa-avancada" }, icon: "" },
            ],
          },
          {
            icon: "fa fa-graduation-cap",
            title: "Turmas",
            exibir: true,
            children: [
              { title: "Ver turmas", to: { name: "turmas" }, icon: "" },
              // { title: "Adicionar turmas", to: "#", icon: "" },
              // { type: "spacer" },
              {
                title: "Gestão de anos",
                to: { name: "series" },
                icon: "fa fa-bookmark",
                permissions: ["series.*", "series.criar"],
              },
              {
                title: "Gestão de disciplinas",
                to: { name: "disciplinas" },
                icon: "fa fa-bookmark",
                permissions: ["disciplinas.*", "disciplinas.criarDisciplina"],
              },
              // { title: "Gestão de disciplinas", to: "#", icon: "" },
              // { title: "Gestão de cursos", to: "#", icon: "" },
            ],
          },
          {
            icon: "fa fa-chalkboard-teacher",
            title: "Diário online",
            exibir: true,
            children: [
              {
                title: "Listar todos",
                to: { name: "gestoesDeAulas" },
                icon: "",
                permissions: ["gestoesDeAulas.*", "gestoesDeAulas.criar"],
              },
            ],
          },
          {
            icon: "fa fa-users",
            title: "Usuários",
            exibir: true,
            children: [
              {
                title: "Ver Professores",
                to: { name: "professores" },
                icon: "fa fa-user-tie",
                permissions: ["users.*"],
              },
              {
                title: "Outros",
                to: { name: "usuarios" },
                icon: "fa fa-bookmark",
                permissions: ["users.*"],
              },
            ],
          },
          {
            icon: "fa fa-money",
            title: "Financeiro",
            exibir: exibirFinanceiro,
            children: [
              {
                title: "Mensalidades",
                to: { name: "mensalidades" },
                permissions: "financeiro.baixarParcela",
                icon: "",
              },
              {
                title: "Gestão de Caixas",
                to: { name: "caixas" },
                permissions: "caixas.abrir",
                icon: "",
              },
            ],
          },
          {
            icon: "mdi-account-network",
            title: "Administração",
            exibir: true,
            children: [
              {
                title: "Dashboard",
                to: { name: "admin" },
                icon: "",
              },
              {
                title: "Franquias",
                to: { name: "franquias" },
                permissions: ["franquias.*", "franquias.criarFranquia"],
                icon: "",
              },
              // {
              //   title: "Inep",
              //   to: { name: "inep" },
              //   icon: "",
              // },
            ],
          },
        ],
        width: "250px",
      },
    };
  },
  methods: {
    block() {
      this.botaoAlerta = false;
    },
    toggleMenu() {
      this.$store.commit("MainTemplate/setMenuOpen", !this.menuOpen);
    },
    dataInicial() {
      const data = Date.now();
      const dataConvertida = new Date(data);
      // dataConvertida.setTime(0);
      dataConvertida.setHours(0);
      dataConvertida.setMinutes(0);
      dataConvertida.setSeconds(0);
      // estabeler temporizador
      dataConvertida.setMinutes(this.durabilidadeDaVidaDoUsuario);
      dataConvertida.setHours(5);
      this.tempo = dataConvertida;
    },
    temporizador() {
      this.idInterval = setInterval(() => {
        const data = new Date(this.tempo);
        data.setSeconds(data.getSeconds() - 1);
        this.tempo = data.toISOString();
        const h = this.zeroFill(data.getHours());
        const m = this.zeroFill(data.getMinutes());
        const s = this.zeroFill(data.getSeconds());
        this.tempoFormatado = `${h}:${m}:${s}`;

        const somaTempo = parseInt(
          this.zeroFill(data.getHours()) +
            this.zeroFill(data.getMinutes()) +
            this.zeroFill(data.getSeconds()),
          10
        );

        if (parseInt(h, 10) === 0 && parseInt(m, 10) === 0 && parseInt(s, 10) === 0) {
          this.$router.push({ name: "logout" });
          return;
        }

        if (!somaTempo) {
          this.limparIntervalo(this.idInterval);
        }

        if (parseInt(h, 10) === 0 && parseInt(m, 10) === 10 && parseInt(s, 10) === 0) {
          this.modalOcioso = true;
        }
      }, 1000);
    },
    limparIntervalo(idInterval) {
      clearInterval(idInterval);
    },
    zeroFill(n) {
      return n <= 9 ? `0${n}` : `${n}`;
    },
    animate(element) {
      if (!element) return;
      const elementWidth = element.offsetWidth ? element.offsetWidth : "";
      const parentWidth = element.parentElement.offsetWidth
        ? element.parentElement.offsetWidth
        : "";
      let flag = 0;

      setInterval(() => {
        element.style.marginLeft = `${flag - 1}px`;

        if (elementWidth === -flag) {
          flag = parentWidth;
        }
      }, 10);
    },
  },
};
</script>

<style>
.lineUp {
  animation: 3s anim-lineUp ease-out infinite;
}

@keyframes anim-lineUp {
  0% {
    opacity: 0;
    transform: translateY(25%);
  }
  15% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
</style>
